import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from "lodash.get";
import React, { useEffect, useRef, useState } from "react";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { alertActions, playerActions, playlistActions, songsActions } from "_actions";
import { playlistService } from "_services";
import moreImage from "_assets/images/more.png";
import { DropdownFilter, IconInfo, ReactTooltipStyled, SortTable } from '_components/Standard';
import { defineTrackLength, Matches } from "_helpers";
import {
  AddToMultipleWithSuggestionsComponent,
  ClientDropdownFilter,
  explicitInfoRowRenderer,
  explicitSortFunction,
  songTitleRowRenderer
} from "_components";
import {
  getActivePlaylistId,
  getPlaylist,
  getPlaylists,
  getPlaylistSchedules,
  getPlaylistSongsView,
  getPlaylistZones,
  getSimilarPlaylists,
  getSimilarPlaylistsLoading,
  isLoadingPlaylistSchedules,
  isLoadingPlaylistZones
} from "_reducers/playlist.reducer";
import {
  AddToMultipleButton,
  BackButton,
  ContextRow,
  DefaultColumnContainer,
  DefaultRowContainer,
  LiveText,
  Loading,
  ReviewButton,
  StandardMoment,
  TabControl,
  theme
} from "_styles";
import { getPlaylistLookupView, getPlaylistStats, isPlaylistReady } from "../_reducers/playlist.reducer";
import { EditPlaylistDetails } from "./EditPlaylistDetails";
import { ModalVerifyPlaylistChanges } from "./ModalVerifyPlaylistChanges";
import { DragTarget, IconCheck, IconPlay, IconPlus, SearchFilter } from './Standard';
import { ModalDecisionBuilder, useOutsideAlerter } from './Utils';
import { AuthenticatedLink } from './AuthenticatedLink';
import { BaseUrl, getBearer } from '_services/api';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Button, Col, Row } from "react-bootstrap";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { SelectionContextMenuSongs } from "./Standard/SelectionContextMenuSongs";
import Split from "@uiw/react-split";
import { SongDetailPanelTabbed } from "../_containers/SongDetailPanelTabbed";
import { SongsSortComponent } from "./SongsSortComponent";

function hashCode(s) {
  var h = 0, l = s.length, i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
}

const Gutter = 18;
const TopContainer = styled(DefaultColumnContainer)`
    height: 350px;
    width: 100%;
    background: linear-gradient(180deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(0, 0, 0, 0.03) 100%);
    padding-left: ${Gutter}px;
    padding-top: 18px;
`;
const BackRow = styled(DefaultRowContainer)`
    height: 42px;
    align-items: center;
`;
const MainRow = styled(DefaultRowContainer)`
    height: 278px;
`;
const TabFilterContainer = styled.div`
    margin: 18px;
`;
// Original reference color: #F7615D or hsl(1.6, 90.6%, 66.7%)
const Image = styled.div`
    background-color: hsl(${props => props.hue}, 90.6%, 66.7%);
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    display: flex;
`;
const TopContainerMainBlock = styled(DefaultColumnContainer)`
    margin-top: 20px;
    margin-left: 20px;
`;
const TitleRow = styled(DefaultRowContainer)`
    align-items: center;
    margin-bottom: 10px;
`;
const Title = styled.div`
    color: #ffffff;
    font-size: 38px;
    margin-right: 20px;
`;
const ChangeStats = styled.div`
    opacity: 0.51;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    margin-left: 14px;
    line-height: 1.2rem;
`;
const Description = styled.div`
    width: 524px;
    color: #AEAEAE;
    font-size: 16px;
`;
const InfoRow = styled(DefaultRowContainer)`
    margin-top: 28px;
    margin-bottom: 10px;
    color: #aeaeae;
    font-size: 16px;
`;
const TagRow = styled(DefaultRowContainer)``;
const Tag = styled.div`
    padding: 3px 8px;
    border: 1px solid #373737;
    border-radius: 1px;
    color: #9b9b9b;
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 5px;
`;
const Container = styled(DefaultColumnContainer)`
    height: 100%;
`;
const TabContainer = styled(DefaultColumnContainer)`
    color: white;
`;
const ScrollingContainer = styled.div`
    overflow-y: auto;
    height: 100%;
`;
const DragBox = styled(DragTarget)`
    flex: 1 1 auto;
`;

const ModalContainer = styled.div`
    position: absolute;
    top: 75px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
`;
const SongsContainer = styled.div``;
const TAB_TRACKS = "#tracks";
const TAB_SCHEDULES = "#schedules";
const TAB_ZONES = "#zones";
const TAB_SIMILAR_LISTS = "#similar_lists";
const TAB_HISTORY = "#history";
const TAB_SONGS_SORT = "#sort";
const tabToIndex = tab => {
  const tabs = [TAB_TRACKS, TAB_SCHEDULES, TAB_ZONES, TAB_SIMILAR_LISTS, TAB_HISTORY, TAB_SONGS_SORT];
  const index = tabs.findIndex(t => t === tab);
  return index >= 0 ? index : 0;
};

const PlaylistLink = styled.a`
    color: white;

    &:hover {
        color: white;
    }
`;

class PlaylistDetail extends React.Component {
  imageUrlInput = React.createRef();

  state = {
    showChangeDialog: false,
    trackFilterText: sessionStorage.getItem("playlist_detail_filter") || "",
    zoneFilterText: "",
    scheduleFilterText: "",
    historyFilterText: "",
    showDeleteConfirmationDialog: false,
    showDuplicateDialog: false,
    showEditPlaylistDetails: false,
    selectedSongs: [],
    downloading: "notstarted",
    downloadingExcel: false,
    playlistArtwork: null,
    clients: this.props.clients,
    shufflePlaylistPlayback: false,
    songs: []
  };
  resetState = () => {
    this.setState({
      showChangeDialog: false,
      zoneFilterText: "",
      scheduleFilterText: "",
      similarPlaylistFilterText: "",
      showDeleteConfirmationDialog: false,
      showEditPlaylistDetails: false,
      selectedSongs: [],
      playlistArtwork: null,
      songInfoId: null
    });
  }

  handleBackButton = () => {
    this.props.history.replace("/playlists");
  };

  onImageFileChanged = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    await playlistService.uploadArtwork(this.props.id, file)
    const imageUrl = URL.createObjectURL(file);
    this.setState({playlistArtwork: imageUrl});
  };

  onSongUpdated = (song) => {
    this.setState({
      songs: this.state.songs.map(s => s.id === song.id ? {
        ...s,
        notes: song.notes,
        verified: song.verified,
        verifier: song.verifier,
        songExplicitType: song.songExplicitType
      } : s)
    });
  }
  handleFilterHistoryChange = (option) => {
    if (!option) {
      this.setState({filterHistoryChange: null});
      return;
    }
    if (option.text === "Add") {
      this.setState({filterHistoryChange: "ADD"})
    } else if (option.text === "Remove") {
      this.setState({filterHistoryChange: "REMOVE"})
    }
  };

  async componentDidMount() {
    this.updatePlaylist(this.props.id);
    this.loadTab();
    if (this.props.songsLinks || this.props.songsLinksMac) {
      const dispatch = this.props.dispatch;
      const playlistId = this.props.id;
      let playlistWithSongsUrl = await playlistService.getWithSongsUrls(this.props.id);
      let songsUrl = playlistWithSongsUrl.songs.map(song => song.songUrl);
      if (this.props.songsLinks) {
        navigator.clipboard.writeText(songsUrl.join("\n")).then(function () {
          alertActions.notificationSuccess(dispatch, "Copied", "songs urls copied to clipboard");
        });
      } else if (this.props.songsLinksMac) {
        const text = `mkdir playlist_${playlistId};cd playlist_${playlistId};` + "echo \"" + songsUrl.join("###") + "\" | tr '###' '\\n' | xargs -n 1 curl -O";
        navigator.clipboard.writeText(text).then(function () {
          alertActions.notificationSuccess(dispatch, "Copied", "Mac songs urls copied to clipboard");
        });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.updatePlaylist(this.props.id);
    }
    if (prevProps.songs !== this.props.songs) {
      this.setState({songs: this.props.songs});
    }
    if (prevProps.tab !== this.props.tab) {
      this.loadTab();
    }
    if (prevProps.songsLinksMac !== this.props.songsLinksMac && this.props.songsLinksMac === true) {
      const dispatch = this.props.dispatch;
      const playlistId = this.props.id;
      let playlistWithSongsUrl = await playlistService.getWithSongsUrls(this.props.id);
      let songsUrl = playlistWithSongsUrl.songs.map(song => song.songUrl);
      const text = `mkdir playlist_${playlistId};cd playlist_${playlistId};` + "echo \"" + songsUrl.join("###") + "\" | tr '###' '\\n' | xargs -n 1 curl -O";
      const history = this.props.history;
      navigator.clipboard.writeText(text).then(function () {
        alertActions.notificationSuccess(dispatch, "Copied", "Mac songs urls copied to clipboard");
        history.replace(`/playlist/${playlistId}`);
      });
    }
  }

  songSelectionHandler = songs => {
    this.setState({selectedSongs: songs});
  }

  loadTab = () => {
    const tab = this.props.tab;
    if (tab === TAB_SCHEDULES) {
      this.props.dispatch(playlistActions.getPlaylistSchedules(this.props.id));
    } else if (tab === TAB_ZONES) {
      this.props.dispatch(playlistActions.getPlaylistZones(this.props.id));
    } else if (tab === TAB_SIMILAR_LISTS) {
      this.props.dispatch(playlistActions.getSimilar(this.props.id));
    } else if (tab === TAB_HISTORY) {
      this.props.dispatch(playlistActions.getHistory(this.props.id));
    } else if (tab === TAB_SONGS_SORT) {

    } else {
      // Tracks
    }
  }

  updatePlaylist = id => {
    const {dispatch} = this.props;
    this.resetState();
    playlistActions.getPlaylist(dispatch, id);
    dispatch(playlistActions.setSelectedPlaylistId(id));
  }

  handleAddSong = song => {
    playlistActions.addSongs(this.props.dispatch, this.props.pinnedPlaylistId, [song.id]);
  }

  handleDeleteSong = song => {
    playlistActions.deleteSongs(this.props.dispatch, this.props.pinnedPlaylistId, [song.id]);
  }

  handleReview = () => {
    this.setState({
      showChangeDialog: true
    });
  };
  closeChangeDialog = (changesSubmitted) => {
    this.setState({
      showChangeDialog: false
    });
    if (changesSubmitted === true) {
      this.updatePlaylist(this.props.id);
    }
  }

  pin = () => {
    this.props.dispatch(playlistActions.setActivePlaylistId(this.props.id));
  }

  switchWithPinned = () => {
    this.props.dispatch(playlistActions.setActivePlaylistId(this.props.id));
    this.props.history.replace("/playlist/" + this.props.pinnedPlaylistId);
  }

  filterTracks = filterText => {
    this.setState({trackFilterText: filterText}, () => {
      sessionStorage.setItem("playlist_detail_filter", filterText);
    });
  };
  filterZones = filterText => {
    this.setState({zoneFilterText: filterText});
  }

  filterSchedules = filterText => {
    this.setState({scheduleFilterText: filterText});
  }

  filterHistory = filterText => {
    this.setState({historyFilterText: filterText});
  };

  filterSimilarPlaylists = filterText => {
    this.setState({similarPlaylistFilterText: filterText});
  };

  handleEditPlaylist = () => {
    this.setState({showEditPlaylistDetails: true});
  }

  handleExportToExcel = () => {
    if (this.state.downloadingExcel) return;

    this.setState({downloadingExcel: true});
    fetch(`${BaseUrl}playlist/${this.props.playlist.id}/excelexport`, {
      method: 'GET', headers: new Headers({
        'Authorization': getBearer()
      })
    }).then(async (response) => {
      this.setState({downloadingExcel: false});
      if (!response.ok) {
        alert("Failed to download");
        return;
      }
      const blob = await response.blob();
      var windowUrl = window.URL || window.webkitURL;
      var url = windowUrl.createObjectURL(blob);
      var anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = "playlist_" + this.props.playlist.id + "_" + this.props.playlist.name + ".xls";
      anchor.click();
      anchor.remove();
      windowUrl.revokeObjectURL(url);
    });

  }

  handleDuplicate = () => {
    this.setState({showDuplicateDialog: true});
  }

  handleDeletePlaylist = () => {
    this.setState({showDeleteConfirmationDialog: true});
  }

  handleInfo = song => {
    this.setState({songInfoId: song.id});
  }

  saveSongsToMultiple = async (playlists, hide) => {
    const {dispatch} = this.props;
    const songIds = this.state.selectedSongs.map(s => s.id);
    // TODO rewire hide()
    hide();
    try {
      const results = playlists.map(pl => playlistActions.addSongs(dispatch, pl.id, songIds));
      await Promise.all(results)
      alertActions.notificationSuccess(dispatch, "Songs Added", `${songIds.length} songs added to ${playlists.length} playlists`);
    } catch (e) {
      alertActions.notificationError(dispatch, "Error", e.toString());
    }
  }


  addSimilarSongsToMultiple = async (songs, hide) => {
    const {dispatch} = this.props;
    const songIds = songs.map(s => s.id);
    const selectedSimilarPlaylists = [];
    hide();
    try {
      const results = selectedSimilarPlaylists.map(pl => playlistActions.addSongs(dispatch, pl.id, songIds));
      await Promise.all(results)
      alertActions.notificationSuccess(dispatch, "Songs Added", `${songIds.length} songs added to ${selectedSimilarPlaylists.length} similar playlists`);
    } catch (e) {
      alertActions.notificationError(dispatch, "Error", e.toString());
    }
  }

  addSimilarSongsToPinned = async (songs, hide) => {
    const {dispatch} = this.props;
    const songIds = songs.map(s => s.id);
    hide();
    try {
      const results = playlistActions.addSongs(dispatch, this.props.pinnedPlaylistId, songIds);
      await Promise.all(results)
      alertActions.notificationSuccess(dispatch, "Songs Added", `${songIds.length} songs added to pinned playlist`);
    } catch (e) {
      alertActions.notificationError(dispatch, "Error", e.toString());
    }
  }

  showDeleteConfirmationDialog = () => {
    this.setState({showDeleteConfirmationDialog: true});
  };
  closeDeleteConfirmationDialog = () => {
    this.setState({showDeleteConfirmationDialog: false});
  };
  playSong = row => {
    playerActions.play(this.props.dispatch, row.id, row.title, row.artist.name, row.artworkUrl)
  }

  playPlaylist = () => {
    playerActions.playPlaylist(this.props.dispatch, this.props.playlist.id, this.state.shufflePlaylistPlayback);
  }

  deletePlaylist = async () => {
    const {dispatch} = this.props;
    this.setState({showDeleteConfirmationDialog: false});
    try {
      const result = await playlistActions.deletePlaylist(this.props.dispatch, this.props.id);
      if (result) {
        alertActions.notificationSuccess(dispatch, "Playlist archived", "");
        this.props.history.replace("/playlists");
      }
    } catch (e) {
      this.props.history.replace("/playlists");
      alertActions.notificationError(dispatch, "Couldn't archive playlist:" + e.userMessage, "");
    }
  }

  handleSongDrop = songs => playlistActions.addSongs(this.props.dispatch, this.props.id, songs.map(s => s.id));
  deleteSongs = songs => playlistActions.deleteSongs(this.props.dispatch, this.props.id, songs.map(s => s.id));
  playlistImageClicked = () => {
    this.imageUrlInput.current.click();
  }

  SimilarPlaylistsTab = ({activePlaylistId}) => {
    const [client, setClient] = useState(null);
    const [showAddToSimilarDialog, setShowAddToSimilarDialog] = useState(false);
    const modalContainerRef = useRef();
    const [showOnlyActive, setShowOnlyActive] = useState(true);
    useOutsideAlerter(modalContainerRef, () => {
      setShowAddToSimilarDialog(false);
    });

    const IncludedSongs = ({playlist, selectedSongs, songStatusChangedTime}) => {
      const [included, setIncluded] = useState(null);
      const [loading, setLoading] = useState(false);
      let selectedSongsCount = selectedSongs.length;
      useEffect(() => {
        if (playlist.includedSongs !== undefined) {
          setIncluded(playlist.includedSongs);
        } else {
          const songsIds = selectedSongs.map(s => s.id).join(",");
          setLoading(true);
          playlistService.getSongsIncludedInPlaylist(playlist.playlist2Id, songsIds).then(included => {
            playlist.includedSongs = included;
            setIncluded(included);
            setLoading(false);
          })
        }
      }, [playlist])
      useEffect(() => {
        if (playlist.playlist2Id === activePlaylistId) {
          const songsIds = selectedSongs.map(s => s.id).join(",");
          setLoading(true);
          playlistService.getSongsIncludedInPlaylist(playlist.playlist2Id, songsIds).then(included => {
            playlist.includedSongs = included;
            setIncluded(included);
            setLoading(false);
          })
        }
      }, [playlist, songStatusChangedTime])

      return <div
          style={{color: included === selectedSongsCount ? "white" : "orange"}}>{loading ? "_" : included}/{selectedSongsCount}</div>;
    }

    const mapStateToPropsIncluded = (state, ownProps) => {
      return {
        songStatusChangedTime: state.songs.songStatusChangedTime,
      };
    };
    const ConnectedIncludedSongs = withRouter(connect(mapStateToPropsIncluded)(IncludedSongs));

    let pinPlaylist = playlist2Id => {
      playlistActions.getPlaylist(this.props.dispatch, playlist2Id);
      this.props.dispatch(playlistActions.setActivePlaylistId(playlist2Id));
    };
    let filterClient = client => {
      setClient(client);
    }
    const playlistActive = (playlistId) => {
      let playlist = this.props.playlists.find(p => p.id === playlistId);
      return playlist && playlist.active;
    }

    const handleShowOnlyActiveChange = (event) => {
      setShowOnlyActive(event.target.checked);
    }

    let columns = [
      {
        fixed: 30,
        rowRenderer: row => {
          // const pinned = activePlaylistId === row.id;
          const pinned = activePlaylistId === row.playlist2Id;
          return (
              <div className="onRowHover">
                            <FontAwesomeIcon icon="thumbtack" size="sm"
                                style={{
                                  color: pinned ? "orange" : "gray",
                                  cursor: "pointer"
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  pinPlaylist(row.playlist2Id);
                                }}
                            />
                        </div>
          )
        }
      },
      {
        percent: 1, field: 'playlistName', title: 'NAME', rowRenderer: row => {
          const tooltipId = `playlist_info_${row.playlist2Id}`;
          return <>
                        <div data-tip data-for={tooltipId}>
                            <span
                                style={{color: playlistActive(row.playlist2Id) ? "#02FF00" : "transparent"}}>●&nbsp;</span>
                            <PlaylistLink href={"#"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(`/playlist/${row.playlist2Id}`)
                                }}
                            > {row.playlistName}</PlaylistLink></div>
                        <ReactTooltipStyled effect='solid' place="bottom" type='light' id={tooltipId}>
                            CLIENT <br />
                          {row.clientName}
                        </ReactTooltipStyled>
                    </>
        }
      },
      {percent: 0.3, field: 'common', title: 'COMMON', isNumber: true},
      {percent: 0.3, field: 'tracks', title: 'TRACKS', isNumber: true},
      {percent: 0.3, field: 'percent', title: 'PERCENT', isNumber: true},
      {
        percent: 0.3, field: 'selection', title: 'selection', isNumber: true, rowRenderer: row => {
          if (this.state.selectedSongs.length === 0) {
            return <div>No tracks selected</div>;
          }
          return <ConnectedIncludedSongs playlist={row}
              selectedSongs={this.state.selectedSongs}></ConnectedIncludedSongs>
        }
      },
    ];
    let rows = Matches(this.props.similarPlaylists, this.state.similarPlaylistFilterText, ['playlistName']);
    rows = showOnlyActive ? rows.filter(r => playlistActive(r.playlist2Id)) : rows;
    rows.sort((a, b) => b.percent - a.percent);
    if (client) {
      rows = rows.filter(r => client ? r.clientId === client.id : true);
    }
    const songStatusChanged = (song) => {
      songsActions.songStatusChanged(this.props.dispatch, song);
      //this.setState({similarPlaylistFilterText: this.state.similarPlaylistFilterText.toLowerCase()+"a"});
    }

    return <TabContainer>
            <TabFilterContainer>
                <Row>
                    <Col xs={"auto"}>
                        <SearchFilter onChange={this.filterSimilarPlaylists}
                            value={this.state.similarPlaylistFilterText} />

                        <AddToMultipleButton
                            enabled={this.state.selectedSongs.length > 0 && activePlaylistId !== -1}
                            count={this.state.selectedSongs.length}
                            pinned={true}
                            onClick={() => setShowAddToSimilarDialog(!showAddToSimilarDialog)} />
                      {showAddToSimilarDialog && <ModalContainer ref={modalContainerRef}>
                            <SelectionContextMenuSongs
                                songStatusChanged={songStatusChanged}
                                songsData={this.state.selectedSongs}
                            />
                        </ModalContainer>}
                    </Col>
                    <Col xs={"auto"}>
                        <ClientDropdownFilter clientSelect={filterClient} />
                    </Col>
                    <Col xs={"auto"}>
                        <FormControlLabel style={{color: '#979797'}} control={<Checkbox style={{color: '#979797'}}
                            checked={showOnlyActive}
                            onChange={handleShowOnlyActiveChange} />}
                            label={"Only active"} />
                    </Col>
                </Row>
            </TabFilterContainer>
            <SortTable
                loading={this.props.similarPlaylistsLoading}
                gutter={Gutter}
                idField='playlist2Id'
                rows={rows}
                columns={columns}
            />
        </TabContainer>;
  }


  HistoryTab = () => {
    let columns = [
      {
        noTooltip: true,
        fixed: 30,
        showOnHover: true,
        rowRenderer: row => (<div className="onRowHover">
                    <IconPlay onClick={e => {
                      e.stopPropagation();
                      this.playSong(row.song);
                    }} /></div>)
      }, {
        noTooltip: true,
        fixed: 20,
        rowRenderer: row => this.props.pinnedPlaylistSongs[row.song.id] ? (
            <IconCheck color='orange' onClick={e => {
              e.stopPropagation();
              this.handleDeleteSong(row.song)
            }} />) : this.props.pinnedPlaylistId !== -1 &&
            <IconPlus onClick={e => {
              e.stopPropagation();
              this.handleAddSong(row.song)
            }} />
      }, {
        noTooltip: true,
        fixed: 20,
        rowRenderer: row => (
            <IconInfo color={'grey'} opacity={.4} onClick={e => {
              e.stopPropagation();
              this.handleInfo(row.song)
            }} />)
      },
      {
        title: 'Track',
        field: 'title',
        percent: 0.25,
        rowRenderer: (row) => songTitleRowRenderer(row.song, this.props.currentPlayingSongId, this.state.songInfoId, this.handleInfo)
      },
      {percent: 0.3, field: 'song.artist.name', title: 'ARTIST'},
      {percent: 0.3, field: 'song.album.name', title: 'ALBUM'},
      {
        percent: 0.3, field: 'user.firstName', title: 'USER', rowRenderer: row => (
            <div>{row.user.firstName + " " + row.user.lastName}</div>
        )
      },
      {percent: 0.3, field: 'song.yearReleased', title: 'YEAR', isNumber: true},
      {
        percent: 0.2, field: 'song.trackLength', title: 'LENGTH', isNumber: true, rowRenderer: row => (
            defineTrackLength(row.song.trackLength))
      },
      {
        title: 'BPM',
        field: 'song.bpm',
        isNumber: true,
        fixed: 70,
        collapse: 800,
        dim: true
      },

      {
        percent: 0.3, field: 'datePublished', title: 'PUBLISHED', isNumber: true, rowRenderer: row => (
            <StandardMoment>{row.datePublished}</StandardMoment>
        )
      },
      {
        fixed: 70,
        title: 'Explicit',
        field: 'song.songExplicitType',
        collapse: 1100,
        sortFunction: explicitSortFunction,
        rowRenderer: row => {
          return explicitInfoRowRenderer(row.song)
        }
      }
    ];
    const playlistHistoryAddedSongIds = this.props.playlistHistory.filter(
        s => s.changeAction == "ADD").map(s => s.song.id);

    let rows;
    if (this.state.filterHistoryChange === "ADD") {
      rows = this.props.playlistHistory.filter(s => s.changeAction === "ADD");
    } else if (this.state.filterHistoryChange === "REMOVE") {
      rows = this.props.playlistHistory.filter(s => s.changeAction === "REMOVE");
    } else {
      const songsNotAddedInitiallyToPlaylist = this.props.playlist.songs.filter(s => !playlistHistoryAddedSongIds.includes(s.id));
      rows = [...this.props.playlistHistory, ...songsNotAddedInitiallyToPlaylist.map(s => {
            return {song: s, user: this.props.playlist.creator, datePublished: s.dateAdded}
          }
      )]
    }
    rows.sort((a, b) => b.datePublished - a.datePublished);
    rows = Matches(rows, this.state.historyFilterText, ["song.title", "song.artist.name", "song.album.name"]);
    return <TabContainer>
            <TabFilterContainer>
                <Row>
                    <Col xs={"auto"}>
                        <SearchFilter onChange={this.filterHistory}
                            value={this.state.historyFilterText} />

                    </Col>
                    <Col xs={"auto"}>
                        <DropdownFilter onSelect={this.handleFilterHistoryChange} placeholder="Change"
                            options={[{id: 0, text: 'Add', start: 0, end: 60},
                              {id: 1, text: 'Remove', start: 0, end: 60}]} />
                    </Col>
                </Row>
            </TabFilterContainer>
            <SortTable
                tableName={'playlist_history'}
                gutter={Gutter}
                idField='id'
                rows={rows}
                columns={columns}
                historyDecorator={true}
            />
        </TabContainer>;
  }

  nextSongClicked = () => {
    if (this.props.tab === TAB_TRACKS || this.props.tab === "") {
      let currentSongInfoIdIndex = this.sortedSongs.findIndex(s => s.id === this.state.songInfoId);
      if (currentSongInfoIdIndex < this.sortedSongs.length - 1) {
        this.setState({songInfoId: this.sortedSongs[currentSongInfoIdIndex + 1].id});
      }
    } else if (this.props.tab === TAB_HISTORY) {


    }
  }
  prevSongClicked = () => {
    if (this.props.tab === TAB_TRACKS || this.props.tab === "") {
      let currentSongInfoIdIndex = this.sortedSongs.findIndex(s => s.id === this.state.songInfoId);
      if (currentSongInfoIdIndex > 0) {
        this.setState({songInfoId: this.sortedSongs[currentSongInfoIdIndex - 1].id});
      }
    } else if (this.props.tab === TAB_HISTORY) {


    }
  }

  moreFromSameArtistClicked = () => {
    const artist = this.state.songs.find(s => s.id === this.state.songInfoId).artist;
    this.props.history.push(`/songs?filterArtist=${artist.name}`);
  }

  render() {
    const {playlistReady, pinnedPlaylistSongs, playlist, changeStats} = this.props;
    const {songs} = this.state;
    if (!playlistReady) return <Loading />;
    const songsFiltered = Matches(songs, this.state.trackFilterText, ["title", "artist.name", "album.name"]);
    if (this.props.song) {
      songsFiltered.forEach(s => {
        let song = this.props.song.find(song => song.id === s.id);
        if (song) {
          s.playlistCount = song.playlistCount
        }
      });
    }
    songsFiltered.forEach(s => {
      s.songExplicitType = s.songExplicitType ? s.songExplicitType : " ";
      const addedToPlaylistByUserFullName = s.addedToPlaylistByUser ? s.addedToPlaylistByUser.firstName + " " + s.addedToPlaylistByUser.lastName : "";
      s["addedToPlaylistByUserFullName"] = addedToPlaylistByUserFullName;
      if (pinnedPlaylistSongs && pinnedPlaylistSongs[s.id]) {
        s["active"] = 1;
      } else {
        s["active"] = 0;
      }
    });

    songsFiltered.sort((a, b) => {
          const dateDiff = a.dateAddedToPlaylist - b.dateAddedToPlaylist;
          if (dateDiff !== 0) {
            return dateDiff;
          } else {
            return a.id - b.id;
          }
        }
    );
    let variant = 'info';
    if (this.state.downloading === 'error') {
      variant = 'danger';
    } else if (this.state.downloading === 'done') {
      variant = 'success';
    }
    const PlaylistExplicitTag = ({explicitType}) => {
      let backgroundColor = "#514E55";
      let color = "#C6C6C6";
      let text = "CLEAN";
      if (explicitType === "EXPLICIT") {
        backgroundColor = "#705057";
        color = "#FF8989";
        text = "EXPLICIT";
      } else if (explicitType === "ADULT") {
        backgroundColor = "#70655B";
        color = "#F8D686";
        text = "ADULT";
      }
      let containsExplicit = explicitType === "CLEAN" && songs.find(s => s.songExplicitType === "EXPLICIT" || s.songExplicitType === "ADULT")
          || explicitType === "ADULT" && songs.find(s => s.songExplicitType === "EXPLICIT");
      return <>
                {containsExplicit && <><ReactTooltipStyled effect='solid' place="bottom" type='light'
                    id={"explicit_warning"}>
                    There are tracks that
                    are {explicitType === "CLEAN" ? songs.find(s => s.songExplicitType === "EXPLICIT") ?
                    (songs.find(s => s.songExplicitType === "ADULT") ? " explicit and adult" : "explicit") :
                    "adult" : "explicit"} in the playlist
                </ReactTooltipStyled><FontAwesomeIcon data-tip data-for={"explicit_warning"}
                    icon={"exclamation-triangle"}
                    size={"xs"}
                    style={{
                      marginTop: 5,
                      color: "#FFA500",
                      marginRight: 5
                    }}></FontAwesomeIcon></>

                }
        <Tag style={{
          backgroundColor,
          color,
          fontSize: 13,
          fontWeight: 400,
          borderRadius: 5,
          fontFamily: "Apercu"
        }}>{text}</Tag></>
    }

    const onSorted = (sorted) => {
      this.sortedSongs = sorted;
      // this.setState({sortedSongs: sorted});
    };


    return (<ThemeProvider theme={theme}>
            <Split lineBar mode={"vertical"}>
                <ScrollingContainer style={{height: this.state.songInfoId ? "50%" : ""}}>
                    <ContextMenu id="playlist_details_settings">
                        <MenuItem onClick={this.handleEditPlaylist}>Edit</MenuItem>
                        <MenuItem><AuthenticatedLink downloading={this.state.downloading}
                            setDownloading={(downloading) => this.setState({
                              ...this.state, downloading: downloading
                            })} url={BaseUrl + "playlist/" + this.props.id + "/download"}
                            filename={playlist.name + ".zip"}>Download</AuthenticatedLink></MenuItem>
                        <MenuItem onClick={() => {
                          //window.location(`/playlist/${this.props.id}/songs_links_mac`);
                          this.props.history.push(`/playlist/${this.props.id}/songs_links_mac`)
                        }
                        }>Download Links</MenuItem>
                        <MenuItem onClick={this.handleExportToExcel}>Export to excel</MenuItem>
                        <MenuItem divider />
                        <MenuItem onClick={this.handleDuplicate}>Duplicate</MenuItem>
                        <MenuItem divider />
                        <MenuItem onClick={this.handleDeletePlaylist}>Archive</MenuItem>
                    </ContextMenu>

                  {this.state.showDeleteConfirmationDialog && (<ModalDecisionBuilder
                      title={"Are you sure you want to archive this playlist?"}
                      handleClose={this.closeDeleteConfirmationDialog}
                      handleAccept={this.deletePlaylist}
                  />)}

                  <Container>
                        {this.state.showDuplicateDialog && (<EditPlaylistDetails
                            id={this.props.id}
                            name={playlist.name}
                            description={playlist.description}
                            internalDescription={playlist.internalDescription}
                            tags={playlist.tags}
                            playlists={this.props.playlists}
                            group={playlist.playlistGroup}
                            allowedExplicitType={playlist.allowedExplicitType}
                            duplicate={true}
                            clients={this.state.clients}
                            availableGroups={playlist.availablePlaylistGroups ? playlist.availablePlaylistGroups.split(",") : []}
                            onEdit={() => {
                              this.setState({showDuplicateDialog: false});
                              this.props.history.push(`/playlists`);
                            }}
                            onBack={() => this.setState({showDuplicateDialog: false})}
                        />)}
                    {this.state.showChangeDialog && (<ModalVerifyPlaylistChanges
                        id={this.props.id}
                        onClose={this.closeChangeDialog}
                    />)}

                    {this.state.showEditPlaylistDetails && (<EditPlaylistDetails
                        id={this.props.id}
                        name={playlist.name}
                        description={playlist.description}
                        internalDescription={playlist.internalDescription}
                        allowedExplicitType={playlist.allowedExplicitType}
                        tags={playlist.tags}
                        group={playlist.playlistGroup}
                        availableGroups={playlist.availablePlaylistGroups ? playlist.availablePlaylistGroups.split(",") : []}
                        onEdit={() => {
                          this.setState({showEditPlaylistDetails: false});
                          this.updatePlaylist(this.props.id);
                        }}
                        onBack={() => this.setState({showEditPlaylistDetails: false})}
                    />)}
                    {this.state.showChangeDialog && (<ModalVerifyPlaylistChanges
                        id={this.props.id}
                        onClose={this.closeChangeDialog}
                    />)}
                    <TopContainer>
                            <BackRow>
                                <BackButton handleBackButton={this.handleBackButton} />
                              {/* <FilterControl /> */}
                            </BackRow>
                            <MainRow>
                                <DefaultColumnContainer>
                                    <Image
                                        hue={!this.props.playlist.artworkUrl && !this.state.playlistArtwork && (hashCode(playlist.name) % 360)}
                                        size={180}
                                        onClick={this.playlistImageClicked.bind(this)}>
                                        {(this.props.playlist.artworkUrl || this.state.playlistArtwork) &&
                                            <>
                                                <img style={{maxWidth: '100%', maxHeight: '100%'}}
                                                    src={this.state.playlistArtwork || this.props.playlist.artworkUrl} />
                                            </>}
                                      <input type='file' id='file' ref={this.imageUrlInput}
                                          style={{display: 'none'}}
                                          onChange={this.onImageFileChanged.bind(this)}
                                          accept="image/png, image/jpeg" />
                                    </Image>
                                    <Button onClick={this.playPlaylist} style={{
                                      marginTop: 15,
                                      backgroundColor: "#FFA500",
                                      borderColor: "white"
                                    }}><FontAwesomeIcon icon={"play"}></FontAwesomeIcon>&nbsp;Play All
                                        <Checkbox icon={<FontAwesomeIcon size={"xs"} icon={"random"}></FontAwesomeIcon>}
                                            checkedIcon={<FontAwesomeIcon color={"white"} size={"xs"}
                                                icon={"random"}></FontAwesomeIcon>}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                            }}
                                            onChange={(event) => {
                                              this.setState({shufflePlaylistPlayback: event.target.checked});
                                            }}></Checkbox></Button>
                                </DefaultColumnContainer>
                                <TopContainerMainBlock>
                                    <ContextRow area="PLAYLIST" title={<div>
                                        <span>{get(playlist, "client.name", "")}</span>{playlist.playlistGroup ? <span
                                        style={{
                                          marginLeft: 10, marginRight: 10, textTransform: "none"
                                        }}><FontAwesomeIcon icon={"folder"}></FontAwesomeIcon><font style={{
                                      marginLeft: 10, color: '#c0af1d'
                                    }}>{playlist.playlistGroup}</font></span> : ""}</div>} />
                                    <TitleRow>
                                        <Title>{playlist.name}</Title>
                                      {this.props.pinnedPlaylistId !== -1 && <FontAwesomeIcon
                                          icon="sync"
                                          style={{
                                            margin: "0px 10px",
                                            color: this.props.pinnedPlaylistId === this.props.id ? "orange" : "white",
                                            cursor: "pointer"
                                          }}
                                          onClick={this.switchWithPinned}
                                      />}
                                      <FontAwesomeIcon
                                          icon="thumbtack"
                                          style={{
                                            margin: "0px 10px",
                                            color: this.props.pinnedPlaylistId === this.props.id ? "orange" : "white",
                                            cursor: "pointer"
                                          }}
                                          onClick={this.pin}
                                      />
                                        <ContextMenuTrigger
                                            id="playlist_details_settings"
                                            holdToDisplay={0}
                                        >
                                            <img
                                                alt="Detail Menu"
                                                src={moreImage}
                                                style={{
                                                  width: "26px",
                                                  height: "26px",
                                                  margin: "0px 10px 0px 10px",
                                                  color: "white",
                                                  cursor: "pointer"
                                                }}
                                            />
                                        </ContextMenuTrigger>
                                      {(this.state.downloading !== "notstarted" ?
                                          <ProgressBar style={{width: "100px", marginRight: "10px"}} animated
                                              now={100} label={this.state.downloading}
                                              variant={variant} /> : "")}
                                      <ReviewButton
                                          enabled={changeStats.changes}
                                          onClick={this.handleReview}
                                      />
                                        <ChangeStats>
                                            Unpublished changes
                                            <br />
                                          {changeStats.added} added {changeStats.removed} removed
                                        </ChangeStats>
                                    </TitleRow>
                                    <Description>Description (client facing):&nbsp;<span
                                        style={{color: "white"}}>{playlist.description}</span></Description>
                                    <Description style={{marginTop: 5}}>Notes (internal):&nbsp;<span
                                        style={{color: "white"}}>{playlist.internalDescription}</span></Description>
                                    <InfoRow>
                                        Created by{" "}
                                      <LiveText>
                                            {playlist.creator.firstName} {playlist.creator.lastName}
                                        </LiveText>{" "}
                                      • {playlist.songs.length} songs, {changeStats.durationString}
                                    </InfoRow>
                                    <TagRow>
                                        {playlist.allowedExplicitType &&
                                            <PlaylistExplicitTag explicitType={playlist.allowedExplicitType} />}
                                      <Tag>
                                            BPMS {changeStats.bpm[0]} - {changeStats.bpm[1]}
                                        </Tag>
                                      {playlist.tags && playlist.tags.map(tag => (<Tag key={tag}>{tag}</Tag>))}
                                    </TagRow>
                                </TopContainerMainBlock>
                            </MainRow>

                            <TabControl
                                selectedIndex={tabToIndex(this.props.tab)}
                                controls={[{
                                  title: "Tracks",
                                  onClick: () => this.props.history.replace(`/playlist/${this.props.id}`)
                                }, {
                                  title: `Schedules (${playlist.scheduleCount})`,
                                  onClick: () => {
                                    this.setState({songInfoId: null});
                                    this.props.history.replace(`/playlist/${this.props.id}${TAB_SCHEDULES}`);
                                  }
                                }, {
                                  title: `Zones (${playlist.zoneCount})`,
                                  onClick: () => {
                                    this.setState({songInfoId: null});
                                    this.props.history.replace(`/playlist/${this.props.id}${TAB_ZONES}`);
                                  }
                                }, {
                                  title: "Similar Lists",
                                  onClick: () => {
                                    this.setState({songInfoId: null});
                                    this.props.history.replace(`/playlist/${this.props.id}${TAB_SIMILAR_LISTS}`);
                                  }
                                },
                                  {
                                    title: "History",
                                    onClick: () => {
                                      this.setState({songInfoId: null});
                                      this.props.history.replace(`/playlist/${this.props.id}${TAB_HISTORY}`);
                                    }
                                  },
                                  {
                                    title: "Sort",
                                    onClick: () => {
                                      this.setState({songInfoId: null});
                                      this.props.history.replace(`/playlist/${this.props.id}${TAB_SONGS_SORT}`);
                                    }
                                  }


                                ]}
                            />
                        </TopContainer>

                    {(!this.props.tab || this.props.tab === TAB_TRACKS) && (
                        <div style={{height: "100%"}}>

                                <SongsContainer style={{
                                  height: "100%",
                                  maxHeight: "100%",
                                }}>
                                    <TabFilterContainer>
                                        <SearchFilter onChange={this.filterTracks} value={this.state.trackFilterText} />
                                        <AddToMultipleWithSuggestionsComponent
                                            playlistId={this.props.id}
                                            songsData={this.state.selectedSongs}
                                            onSave={this.saveSongsToMultiple}
                                            playlists={this.props.playlists} />
                                    </TabFilterContainer>
                                    <DragBox
                                        dragClassName='drag-hover'
                                        mimeType={'application/songs'}
                                        onDrop={this.handleSongDrop}
                                    >
                                        <SortTable
                                            tableName={"playlist_detail"}
                                            gutter={Gutter}
                                            loading={!playlistReady}
                                            onRowDoubleClick={this.playSong}
                                            onSelection={this.songSelectionHandler}
                                            onDelete={this.deleteSongs}
                                            onSorted={onSorted}
                                            rows={songsFiltered}
                                            columns={[{
                                              fixed: 30,
                                              noTooltip: true,
                                              showOnHover: true,
                                              rowRenderer: row => (<div className="onRowHover">
                                                    <IconPlay onClick={e => {
                                                      e.stopPropagation();
                                                      this.playSong(row);
                                                    }} /></div>)
                                            }, {
                                              fixed: 20,
                                              noTooltip: true,
                                              field: 'active',
                                              isNumber: true,
                                              rowRenderer: row => this.props.pinnedPlaylistSongs[row.id] ? (
                                                  <IconCheck color='orange' onClick={e => {
                                                    e.stopPropagation();
                                                    this.handleDeleteSong(row)
                                                  }} />) : this.props.pinnedPlaylistId !== -1 && <IconPlus onClick={e => {
                                                e.stopPropagation();
                                                this.handleAddSong(row)
                                              }} />
                                            }, {
                                              fixed: 20,
                                              noTooltip: true,
                                              rowRenderer: row => (
                                                  <IconInfo color={'grey'} opacity={.4} onClick={e => {
                                                    e.stopPropagation();
                                                    this.handleInfo(row)
                                                  }} />)
                                            }, {
                                              title: 'Track',
                                              field: 'title',
                                              percent: 25,
                                              rowRenderer: (row) => songTitleRowRenderer(row, this.props.currentPlayingSongId, this.state.songInfoId, this.handleInfo)
                                            }, {
                                              title: 'Artist',
                                              field: 'artist.name',
                                              percent: 20
                                            }, {title: 'Album', field: 'album.name', percent: 20}, {
                                              title: 'User',
                                              fixed: 130,
                                              field: "addedToPlaylistByUserFullName",
                                              collapse: 750,
                                              rowRenderer: row => {
                                                if (row.addedToPlaylistByUser) {
                                                  return <LiveText>{`${row.addedToPlaylistByUser.firstName} ${row.addedToPlaylistByUser.lastName}`}</LiveText>
                                                } else {
                                                  return "";
                                                }
                                              }
                                            }, {
                                              title: 'BPM',
                                              field: 'bpm',
                                              isNumber: true,
                                              fixed: 60,
                                              collapse: 800,
                                              dim: true
                                            }, {
                                              title: 'Year',
                                              field: 'yearReleased',
                                              isNumber: true,
                                              fixed: 60,
                                              collapse: 900,
                                              dim: true
                                            }, {
                                              title: 'length',
                                              field: 'trackLength',
                                              isNumber: true,
                                              fixed: 60,
                                              collapse: 1000,
                                              dim: true,
                                              rowRenderer: row => (defineTrackLength(row.trackLength))
                                            }, {
                                              fixed: 170,
                                              title: 'Added (Local)',
                                              field: 'dateAddedToPlaylist',
                                              isNumber: true,
                                              collapse: 1100,
                                              dim: true,
                                              rowRenderer: row => (
                                                  <StandardMoment>{row.dateAddedToPlaylist}</StandardMoment>)
                                            }, {
                                              fixed: 70,
                                              title: 'Explicit',
                                              field: 'songExplicitType',
                                              sortFunction: explicitSortFunction,
                                              collapse: 1100,
                                              rowRenderer: row => explicitInfoRowRenderer(row),
                                            },
                                              {
                                                name: 'REPLAY_GAIN',
                                                fixed: 60,
                                                title: 'RGAIN',
                                                isNumber: true,
                                                field: 'replayGainValue',
                                                collapse: 1000,
                                                dim: true,
                                              },
                                              {
                                                name: 'LUFS',
                                                fixed: 50,
                                                title: 'LUFS',
                                                isNumber: true,
                                                field: 'lufsValue',
                                                collapse: 1000,
                                                dim: true,
                                                rowRenderer: row => {
                                                  let color = "";
                                                  if (row.lufsValue <= -13.0) {
                                                    color = "#C01C4E";
                                                  }
                                                  if (row.lufsValue > -13 && row.lufsValue <= -12.5) {
                                                    color = "#D29C1A";
                                                  }
                                                  if (row.lufsValue > -12.5) {
                                                    color = "";
                                                  }
                                                  if (row.lufsValue >= 9.4) {
                                                    color = "#D29C1A";
                                                  }
                                                  return <span style={{color: color}}>{row.lufsValue}</span>
                                                }
                                              },
                                              (this.props.song && {
                                                name: 'playlists',
                                                fixed: 90,
                                                title: 'playlists',
                                                field: 'playlistCount',
                                                isNumber: true,
                                              })]}
                                        />
                                              </DragBox>
                                              </SongsContainer>
                                              </div>)}

                    {this.props.tab === TAB_SCHEDULES && (<TabContainer>
                            <TabFilterContainer>
                                <SearchFilter onChange={this.filterSchedules}
                                    value={this.state.scheduleFilterText} />
                            </TabFilterContainer>
                            <SortTable
                                tableName={'playlist_schedule'}
                                loading={this.props.schedulesLoading}
                                gutter={Gutter}
                                rows={Matches(this.props.schedules, this.state.scheduleFilterText, ["client.name", "name"])}
                                onRowClick={row => this.props.history.push(`/schedule/${row.id}`)}
                                columns={[{percent: 1, field: 'client.name', title: 'client'}, {
                                  percent: 1,
                                  field: 'name',
                                  title: 'schedule name'
                                }, {percent: 1, field: 'zones.length', title: 'zones', isNumber: true}, {
                                  percent: 1,
                                  field: 'dayParts.length',
                                  title: 'day parts',
                                  isNumber: true
                                }, {
                                  percent: 1,
                                  field: 'dateCreated',
                                  title: 'last updated (Local)',
                                  isNumber: true,
                                  rowRenderer: row => {
                                    if (row.dateCreated !== undefined) return (
                                        <StandardMoment>{new Date(row.dateCreated)}</StandardMoment>);
                                    return `No date available`;
                                  }
                                },]}
                            />
                        </TabContainer>)}
                    {this.props.tab === TAB_ZONES && (<TabContainer>
                            <TabFilterContainer>
                                <SearchFilter onChange={this.filterZones} value={this.state.zoneFilterText} />
                            </TabFilterContainer>
                            <SortTable
                                tableName={'playlist_zones'}
                                loading={this.props.zonesLoading}
                                gutter={Gutter}
                                rows={Matches(this.props.zones, this.state.zoneFilterText, ['location.client.name', 'location.name', 'name', 'schedule.name', 'player.name'])}
                                columns={[{percent: 1, field: 'location.client.name', title: 'client'}, {
                                  percent: 1,
                                  field: 'location.name',
                                  title: 'location'
                                }, {percent: 1, field: 'name', title: 'zone'}, {
                                  percent: 1,
                                  field: 'schedule.name',
                                  title: 'schedule'
                                }, {percent: 1, field: 'player.name', title: 'player'}]}
                            />
                        </TabContainer>)}
                    {this.props.tab === TAB_SIMILAR_LISTS &&
                        <this.SimilarPlaylistsTab
                            activePlaylistId={this.props.pinnedPlaylistId}
                            activeSongs={this.props.activeSongs}></this.SimilarPlaylistsTab>
                    }
                    {this.props.tab === TAB_HISTORY &&
                        <this.HistoryTab
                            activePlaylistId={this.props.pinnedPlaylistId}
                            activeSongs={this.props.activeSongs}></this.HistoryTab>
                    }
                    {this.props.tab === TAB_SONGS_SORT &&
                        <SongsSortComponent
                            dispatch={this.props.dispatch}
                            playlistSongs={this.props.songs}
                            playlistId={this.props.id}>
                            </SongsSortComponent>
                    }
                    </Container>
                </ScrollingContainer>
              {
                  this.state.songInfoId &&
                  <SongDetailPanelTabbed id={this.state.songInfoId}
                      nextSongClicked={this.nextSongClicked.bind(this)}
                      prevSongClicked={this.prevSongClicked}
                      moreFromSameArtistClicked={this.moreFromSameArtistClicked}
                      onSongUpdated={this.onSongUpdated}
                      height={"50%"}
                      closeClicked={(refresh) => {
                        this.setState({songInfoId: null})
                        if (refresh) {
                          this.updatePlaylist(this.props.id);
                        }
                      }}></SongDetailPanelTabbed>
              }

            </Split>
                      </ThemeProvider>)
        ;
  }
}

class PlaylistDecorator {

  constructor(playlistChange) {
    this.playlistChange = playlistChange;
  }

}

const mapStateToProps = (state, ownProps) => {
  const id = parseInt(ownProps.id || ownProps.match.params.id);
  // get url path
  const songsLinks = ownProps.location.pathname.endsWith("songs_links");
  const songsLinksMac = ownProps.location.pathname.endsWith("songs_links_mac");
  const pinnedPlaylistId = getActivePlaylistId(state);
  const pinnedPlaylistSongs = getPlaylistLookupView(state, pinnedPlaylistId);
  return {
    id,
    songsLinks,
    songsLinksMac,
    pinnedPlaylistId: pinnedPlaylistId,
    tab: ownProps.location.hash,
    playlistReady: isPlaylistReady(state, id),
    pinnedPlaylistSongs: pinnedPlaylistSongs,
    songs: getPlaylistSongsView(state, id),
    playlist: getPlaylist(state, id),
    playlists: getPlaylists(state),
    similarPlaylists: getSimilarPlaylists(state, id),
    similarPlaylistsLoading: getSimilarPlaylistsLoading(state, id),
    changeStats: getPlaylistStats(state, id),
    schedulesLoading: isLoadingPlaylistSchedules(state, id),
    schedules: getPlaylistSchedules(state, id),
    zonesLoading: isLoadingPlaylistZones(state, id),
    zones: getPlaylistZones(state, id),
    clients: state.client.client,
    currentPlayingSongId: state.player.songId,
    song: state.songs ? state.songs.songs : null,
    playlistHistory: state.playlists ? state.playlists.playlistHistory.list : null,
  };
};
const connectedPlaylistDetail = withRouter(connect(mapStateToProps)(PlaylistDetail));
export { connectedPlaylistDetail as PlaylistDetail };

