import {api} from './api';

export const feedbackService = {
  getAllVotes,
  updateSongVote,
  archive
};

function getAllVotes() {
  return api("GET", "song/votes2", undefined, votes => votes);
}

function updateSongVote(songVote, id) {
  return api("PUT", `song/vote/${id}`, songVote, songVoteResponse => songVoteResponse)
}

function archive(id) {
    return api("GET", `client/${id}/archive`, undefined, response => response);
}

