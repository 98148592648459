import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import {
  AddToMultipleComponent,
  durationToString,
  explicitInfoRowRenderer,
  IconCheck,
  IconHover,
  IconPlus,
  ModalDecisionBuilder,
  SearchFilter,
  stringContainsWords,
  wordsContainedInString
} from "_components";
import { getPlaylistLookupView, getPlaylists } from "_reducers/playlist.reducer";
import { IconPlay, SortTable } from '_components/Standard';
import { alertActions, playerActions, playlistActions, songsActions } from "_actions";
import { Matches } from '_helpers';
import { CheckboxButton, DefaultLabel, StandardMoment, TabControl } from "_styles";
import JSONTree from 'react-json-tree';
import get from 'lodash.get';
import { Button, Col, Container, Form, FormText, Row } from "react-bootstrap";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import { songsService } from "../_services";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "react-avatar";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import axios from "axios";
import https from "https";
import imagePlaceHolder from "../_assets/images/talea-album-placeholder.png";
import { adultWords, ExplicitAvatar, ExplicitBadge, explicitWords } from "../_components/ExplicitInfoRowRenderer";

const SongName = styled.div`
    color: white;
    margin-left: 12px;
    font-size: 18px;
    text-overflow: ellipsis;
    flex: 1;
`;

const ArtistName = styled.div`
    color: #959595;
    font-size: 14px;
    text-overflow: ellipsis;
    flex: 1;
`;

const AlbumArt = styled.img`
    border: none;
    width: 64px;
    height: 64px;
    margin-right: 8px;
    border-radius: 5px;
`;
const transparent = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

const PlaylistLink = styled.a`
    color: white;

    &:hover {
        color: white;
    }
`;

const Text = styled(DefaultLabel)`
    color: white;
    margin-right: 20px;
`;

const theme = {
  base00: 'transparent',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633'
};

const TAB_PLAYLISTS = "playlists";
const TAB_METADATA = "metadata";
const TAB_EXPLICIT = "explicit";


const tabToIndex = tab => {
  const tabs = [TAB_PLAYLISTS, TAB_METADATA, TAB_EXPLICIT];
  const index = tabs.findIndex(t => t === tab);
  return index >= 0 ? index : 0;
};


const explicitAvatarFor = (explicit, downloadingLyrics, explicitLyrics) => {
  let explicitType = "_";
  if (!downloadingLyrics) {
    explicitType = explicit ? "EXPLICIT" : "CLEAN";
    if (explicitLyrics) {
      explicitType = explicitLyrics;
    }
  }

  return ExplicitAvatar(explicitType, true)
}

function getExplicitSuggestion(musixMatchExplicit, spotifyExplicit, lyricsExplicit) {
  if (musixMatchExplicit) return "EXPLICIT";
  if (spotifyExplicit) return "EXPLICIT";
  return lyricsExplicit;
}

class SongDetailPanelTabbed extends React.Component {
  state = {
    search: '',
    showConfirmArchiving: false,
    explicitTypeChanged: false,
    selectedExplicitType: this.props.song.songExplicitType,
    verified: this.props.song.verified ? true : false,
    notes: null,
    selectedTab: sessionStorage.getItem("song_panel_selected_tab") || TAB_PLAYLISTS,
    song: this.props.song,
    downloadingLyrics: false,
    lyricsData: null,
  }

  componentDidMount() {
    songsActions.getSongPlaylists(this.props.dispatch, this.props.id);
    songsActions.getOne(this.props.dispatch, this.props.id);
    window.addEventListener("keydown", this.handleKeyDown);
    this.containerElement && setTimeout(() => this.containerElement.focus(), 500);

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.clearFields();
      songsActions.getOne(this.props.dispatch, this.props.id);
      songsActions.getSongPlaylists(this.props.dispatch, this.props.id);
    }
    if (prevProps.song !== this.props.song) {
      this.setState({
        song: this.props.song,
        downloadingLyrics: false,
        lyrics: null,
        selectedExplicitType: this.props.song.songExplicitType,
        verified: this.props.song.verified ? true : false,
        notes: this.props.song.notes ? this.props.song.notes : ""
      })
      if (this.state.selectedTab === TAB_EXPLICIT) {
        this.downloadLyrics();
      }
    }
    const height = this.containerElement.clientHeight;
    if (height !== this.state.height) {
      this.setState({height});
      if (this.props.heightChanged) {
        this.props.heightChanged(height);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const {nextSongClicked, prevSongClicked, closeClicked} = this.props;
    // right cursor key
    if (nextSongClicked && e.key === "ArrowRight") {
      nextSongClicked();
    }
    // left cursor key
    if (prevSongClicked && e.key === "ArrowLeft") {
      prevSongClicked();
    }
    // enter key
    if (e.key === "Enter") {
      this.playSong();
    }
    if (closeClicked && e.key === "Escape") {
      closeClicked();
    }
  }

  downloadLyrics = () => {
    this.setState({downloadingLyrics: true});
    const axiosParams = {
      method: "GET",
      url: "https://lyrics.taleamusic.com/lyrics/",
      params: {
        artist: this.props.song.artist.name,
        title: this.props.song.title
      }

    }
    const agent = new https.Agent({
      rejectUnauthorized: false
    });

    axios(axiosParams, {httpsAgent: agent}).then((response) => {
      const lyrics = response.data.lyrics;
      const lyricsUrl = response.data.url;
      const lyricsHasExplicitWords = stringContainsWords(lyrics, explicitWords);
      const lyricsHasAdultWords = stringContainsWords(lyrics, adultWords);
      const lyricsIsClean = !lyricsHasExplicitWords && !lyricsHasAdultWords;
      const foundExplicitWords = lyricsHasExplicitWords ? wordsContainedInString(lyrics, explicitWords) : [];
      const foundAdultWords = lyricsHasAdultWords ? wordsContainedInString(lyrics, adultWords) : [];
      let coloredLyrics = lyrics;
      if (lyricsHasExplicitWords) {
        const regex = new RegExp("\\b" + foundExplicitWords.join("\\b|\\b") + "\\b", "gi");
        // Replace each explicit word with a span element
        coloredLyrics = coloredLyrics.replace(regex, (match) => {
          return `<span style="color:#FF8989">${match}</span>`;
        });
      }
      if (lyricsHasAdultWords) {
        const regex = new RegExp("\\b" + foundAdultWords.join("\\b|\\b") + "\\b", "gi");
        // Replace each explicit word with a span element
        coloredLyrics = coloredLyrics.replace(regex, (match) => {
          return `<span style="color:#F8D686">${match}</span>`;
        });
      }
      this.setState({
        lyricsData: {
          lyrics: lyrics,
          coloredLyrics: coloredLyrics,
          musixMatchExplicit: response.data.musixMatchExplicit,
          spotifyExplicit: response.data.spotifyExplicit,
          foundAdultWords: foundAdultWords,
          foundExplicitWords: foundExplicitWords,
          lyricsExplicit: lyricsHasExplicitWords ? "EXPLICIT" : lyricsHasAdultWords ? "ADULT" : "CLEAN",
          lyricsIsClean: lyricsIsClean,
          lyricsUrl: lyricsUrl
        },
        downloadingLyrics: false
      });
    }).catch((error) => {
      console.log(error);
      this.setState({lyrics: "No lyrics found", downloadingLyrics: false});
    });
  }

  clearFields() {
    this.setState({
      search: '',
      showConfirmArchiving: false,
      explicitTypeChanged: false,
      selectedExplicitType: this.props.song.songExplicitType,
      verified: this.props.song.verified ? true : false,
      songEdited: false,
      lyricsData: null,
      notes: null
    })
  }

  handleSearch = filterText => {
    this.setState({search: filterText});
  }

  openSongLyricsInGenius = () => {
    // open url in new tab
    window.open(this.state.lyricsData ? this.state.lyricsData.lyricsUrl : "", "_blank");
  }

  openTaleaLyricsUrl = () => {
    // open url in new tab
    window.open("https://3.229.239.233:3000", "_blank");
  }

  pinPlaylist = playlistId => {
    if (this.props.activePlaylistId === playlistId) {
      this.props.dispatch(playlistActions.setActivePlaylistId(-1));
    } else {
      playlistActions.getPlaylist(this.props.dispatch, playlistId);
      this.props.dispatch(playlistActions.setActivePlaylistId(playlistId));
    }
  }

  saveSongToMultiple = async (playlists, hide) => {
    const {dispatch} = this.props;
    const songIds = [this.props.id];

    hide();

    try {
      const results = playlists.map(pl => playlistActions.addSongs(dispatch, pl.id, songIds));
      await Promise.all(results)
      alertActions.notificationSuccess(dispatch, "Song Added", `${this.props.song.title} added to ${playlists.length} playlists`);
    } catch (e) {
      alertActions.notificationError(dispatch, "Error", e.toString());
    }
  }


  closeDeleteConfirmationDialog = () => {
    this.setState({showConfirmArchiving: false});
  }

  doArchiving = async () => {
    const {dispatch, song} = this.props;
    const songId = song.id;
    const result = await songsActions.archive(dispatch, songId);
    if (result) {
      alertActions.notificationSuccess(dispatch, "Song archived", "");
      songsActions.getSongs(dispatch);
      if (this.props.history.length === 1) {
        this.props.history.push("/songs");
      } else {
        this.props.closeClicked(true)
      }

    }

  }

  playSong = () => {
    playerActions.play(this.props.dispatch, this.state.song.id, this.state.song.title, this.state.song.artist.name
        , this.state.song.artworkUrl)
  }

  handleAddSong = () => {
    playlistActions.addSongs(this.props.dispatch, this.props.activePlaylistId, [this.props.song.id])
  };

  handleRemoveSong = () => {
    playlistActions.deleteSongs(this.props.dispatch, this.props.activePlaylistId, [this.props.song.id])
  };

  changeExplicitType = (explicitType) => {
    this.setState({songEdited: true, selectedExplicitType: explicitType})
  };

  changeNotes(notes) {
    this.setState({songEdited: true, notes: notes})
  }

  changeVerified(verified) {
    this.setState({songEdited: true, verified: verified})
  }


  async updateButtonClicked() {
    this.setState({explicitTypeChanged: false});
    await songsService.updateSongExplicitType(this.props.song.id, this.state.selectedExplicitType, this.state.verified, this.state.notes);
    alertActions.notificationSuccess(this.props.dispatch, "Song updated", "Song updated successfully");
    const updatedSong = {
      ...this.state.song,
      verified: this.state.verified,
      verifier: this.state.verified ? `${this.props.userDetails.firstName} ${this.props.userDetails.lastName}` : null,
      notes: this.state.notes,
      verifiedAt: this.state.verified ? new Date() : null,
      songExplicitType: this.state.selectedExplicitType
    };
    this.setState({song: updatedSong})
    this.props.onSongUpdated(updatedSong);
  }

  render() {
    const {playlists, loading, height} = this.props;
    const {
      song,
      lyricsData,
      downloadingLyrics,
    } = this.state;
    let explicitSuggestion = "_";
    let musixMatchExplicit = false;
    let spotifyExplicit = false;
    let lyricsExplicit = false;
    if (lyricsData) {
      explicitSuggestion = getExplicitSuggestion(lyricsData.musixMatchExplicit, lyricsData.spotifyExplicit, lyricsData.lyricsExplicit)
      musixMatchExplicit = lyricsData.musixMatchExplicit;
      spotifyExplicit = lyricsData.spotifyExplicit;
      lyricsExplicit = lyricsData.lyricsExplicit;
    }
    const data = Matches(playlists, this.state.search, ["name"]);
    if (song.dateAdded) {
      let dateAdded = song["dateAdded"];
      let dateModified = song["dateModified"];
      let trackLength = song["trackLength"];
      let formattedDateAdded = moment(dateAdded).format("MM/DD/YYYY hh:mm");
      let formattedDateModified = dateModified ? moment(dateModified).format("MM/DD/YYYY hh:mm") : undefined;
      let formattedTrackLength = durationToString(trackLength);
      song["dateAdded"] = formattedDateAdded;
      song["dateModified"] = formattedDateModified;
      song["trackLength"] = formattedTrackLength;
    }
    let songmetadata_tab =
        <Container style={{paddingTop: 15}} fluid>
          <Col>
            <Row noGutters>
              <FormLabel id="demo-radio-buttons-group-label" style={{color: "#4A494E"}}>SONG
                METADATA</FormLabel>
            </Row>
            <Row>
              <JSONTree data={song} theme={theme} invertTheme={false} />
            </Row>
          </Col>
        </Container>;
    let explicitForm_tab = <Container style={{paddingTop: 15}} fluid>
      <Row style={{paddingLeft: 15, paddingRight: 15}}>
        <Col xs={6}>
          <Row style={{flex: 1, paddingBottom: 20}}>
            <FormControl style={{color: "white", width: "100%"}}>
              <Container fluid>
                <Row>
                  <FormLabel id="demo-radio-buttons-group-label"
                      style={{color: "#4A494E", marginBottom: 0}}>
                    TAGGING
                  </FormLabel>
                </Row>
                <Row className={"align-items-center"}>
                  <Col xs={6} style={{paddingLeft: 0}}>
                    <RadioGroup row>
                      <FormControlLabel style={{marginBottom: 0}} value="no"
                          control={<Radio
                              checked={this.state.selectedExplicitType === "CLEAN"}
                              onChange={() => this.changeExplicitType("CLEAN")}
                              style={{color: "#EBAD18"}} />} label="Clean" />
                      <FormControlLabel style={{marginBottom: 0}} value="adult"
                          control={<Radio
                              checked={this.state.selectedExplicitType === "ADULT"}
                              onChange={() => this.changeExplicitType("ADULT")}
                              style={{color: "#EBAD18"}} />}
                          label="Adult" />
                      <FormControlLabel style={{marginBottom: 0}} value="explicit"
                          control={<Radio
                              checked={this.state.selectedExplicitType === "EXPLICIT"}
                              onChange={() => this.changeExplicitType("EXPLICIT")}
                              style={{color: "#EBAD18"}} />}
                          label="Explicit" />
                    </RadioGroup>
                  </Col>
                  <Col xs={3}>
                    <Row style={{marginBottom: 0, marginLeft: -3}}>
                      <FormControlLabel style={{height: 36, marginBottom: 0}} control={
                        <>
                          <CheckboxButton
                              checked={this.state.verified}
                              onChange={e => {
                                this.changeVerified(e.target.checked);
                              }}
                          />
                          <Text>Verified</Text>
                          {this.state.song.verifier &&
                              <Avatar style={{paddingTop: 2}}
                                  round name={this.state.song.verifier}
                                  size={36} />}
                        </>
                      }>
                      </FormControlLabel>
                    </Row>
                    {this.state.song.verifiedAt && <Row>
                      <FormText style={{
                        color: "#6f6c75",
                        marginTop: 0
                      }}>{moment(this.state.song.verifiedAt).format("MM/DD/YYYY hh:mm")}</FormText>
                    </Row>}
                  </Col>
                  <Col xs={3} className="align-items-right justify-content-end">
                    <FormControlLabel control={<Button style={{background: "#EBAD18", border: 0}}
                        disabled={!this.state.songEdited}
                        onClick={this.updateButtonClicked.bind(this)}>Update</Button>}>
                    </FormControlLabel>
                  </Col>
                </Row>
              </Container>
            </FormControl>
          </Row>
          <Row>
            <FormLabel id="demo-radio-buttons-group-label" style={{color: "#4A494E"}}>
              SUGGESTED
            </FormLabel>
          </Row>
          <Row>
            <Col xs={"auto"} style={{paddingLeft: 0, paddingTop: 3, paddingRight: 5}}>
              <FormText style={{color: "#AEAEAE", marginTop: 0}}>Suggested:</FormText>
            </Col>
            <Col xs={"auto"} style={{padding: 0}}>
              {ExplicitBadge(explicitSuggestion, true)}
            </Col>
            <Col xs={"auto"} style={{paddingLeft: 10, paddingTop: 3, paddingRight: 5}}>
              <FormText style={{color: "#AEAEAE", marginTop: 0}}>MusixMatch:</FormText>
            </Col>
            <Col xs={"auto"} style={{padding: 0}}>
              {explicitAvatarFor(musixMatchExplicit, downloadingLyrics)}
            </Col>
            <Col xs={"auto"} style={{paddingLeft: 10, paddingTop: 3, paddingRight: 5}}>
              <FormText style={{color: "#AEAEAE", marginTop: 0}}>Spotify:</FormText>
            </Col>
            <Col xs={"auto"} style={{padding: 0}}>
              {explicitAvatarFor(spotifyExplicit, downloadingLyrics)}
            </Col>
            <Col xs={"auto"} style={{paddingLeft: 10, paddingTop: 3, paddingRight: 5}}>
              <FormText style={{color: "#AEAEAE", marginTop: 0}}>Lyrics:</FormText>
            </Col>
            <Col xs={"auto"} style={{padding: 0}}>
              {explicitAvatarFor(false, downloadingLyrics, lyricsExplicit)}
            </Col>
          </Row>
          <Row style={{flex: 1, marginTop: 20, paddingBottom: 20}}>
            <Col xs={12} style={{padding: 0}}>
              <FormControl style={{color: "white", width: "100%"}}>
                <FormLabel style={{color: "#4A494E"}}>NOTES
                </FormLabel>
                <Form value={this.state.notes ? this.state.notes : ""} as="textarea"
                    rows={3} placeholder={"notes"}
                    style={{width: "95%", borderRadius: 5, marginTop: 5}}
                    onChange={e => {
                      this.changeNotes(e.target.value);
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                />
              </FormControl>
            </Col>
          </Row>
        </Col>
        <Col xs={6} style={{borderLeft: "1px solid #4A494E", paddingLeft: 45}}>
          <Row>
            <Col style={{padding: 0}}>
              <FormLabel id="demo-radio-buttons-group-label" style={{color: "#4A494E"}}
                  onClick={this.openTaleaLyricsUrl}>
                LYRICS
              </FormLabel>
            </Col>
            <Col style={{display: 'flex', justifyContent: 'right'}}>
              <FormLabel id="demo-radio-buttons-group-label" style={{color: "#4A494E", cursor: "pointer"}}
                  onClick={this.openSongLyricsInGenius}>
                {lyricsData && lyricsData.lyricsUrl ?
                    lyricsData.lyricsUrl.substring(lyricsData.lyricsUrl.lastIndexOf('/') + 1) : ""}
              </FormLabel>
            </Col>
          </Row>
          {lyricsData && lyricsData.lyricsExplicit !== "CLEAN" && this.explicitWordsInfoRow()}
          <Row style={{marginTop: 10}}>
            {downloadingLyrics && <div style={{color: "white"}}>Downloading lyrics...</div>}
            {lyricsData &&
                <div style={{color: "white", lineHeight: 1.5, fontSize: 16}}
                    dangerouslySetInnerHTML={{
                      __html: lyricsData.coloredLyrics
                          ? lyricsData.coloredLyrics.replace(/\n/g, "<br />") : ""
                    }} />}
          </Row>
        </Col>
      </Row>
    </Container>
    let playlists_tab = <Container style={{paddingTop: 15}} fluid>
      <Col>
        <Container fluid>
          <Row style={{marginBottom: 10}} noGutters>
            <SearchFilter onChange={this.handleSearch} value={this.state.search} autofocus={false} />
            <div style={{marginTop: 5}}>
              <AddToMultipleComponent
                  songsData={[song]}
                  onSave={this.saveSongToMultiple}
                  playlists={this.props.allPlaylists} />
            </div>
          </Row>
          <Row>
            <Col style={{padding: 0, marginLeft: 0, marginRight: 0}}>
              <SortTable
                  tableName={'detail_playlist'}
                  loading={loading}
                  rows={data}
                  columns={[
                    {
                      fixed: 25, field: 'playlist', title: '', rowRenderer: row => {
                        const pinned = this.props.activePlaylistId === row.id;
                        return (
                            <div className={!pinned && "onRowHover"}>
                              <FontAwesomeIcon icon="thumbtack" size="sm"
                                  style={{
                                    color: pinned ? "orange" : "gray",
                                    cursor: "pointer"
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    this.pinPlaylist(row.id);
                                  }}
                              />
                            </div>
                        )

                      }
                    },
                    {percent: 1, field: 'clientName', title: 'client'},
                    {
                      percent: 1, field: 'name', title: 'playlist', rowRenderer: row => {
                        return <div>
                          <PlaylistLink href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push(`/playlist/${row.id}`)
                              }}> {row.name}</PlaylistLink>
                        </div>
                      }
                    },
                    {
                      percent: 1,
                      field: 'dateAdded',
                      isNumber: true,
                      title: 'Added (Local)',
                      rowRenderer: row => (
                          <StandardMoment>{row.dateAdded}</StandardMoment>
                      )
                    }
                  ]}
              />
            </Col>
          </Row>
        </Container>
      </Col>
    </Container>;

    return <Container style={{height, overflowY: "scroll", background: "#1B1A20"}}
        ref={(containerElement) => {
          this.containerElement = containerElement
        }}
        fluid>
      {this.props.closeClicked &&
          <IconHover style={{position: "sticky", height: 20, width: 20, marginLeft: "auto", top: 26, zIndex: 20000}}
              color='white' opacity=".3" icon="times"
              onClick={() => {
                if (this.props.history.length === 1) {
                  this.props.history.push("/songs");
                } else {
                  this.props.closeClicked()
                }
              }} title="Close" />
      }
      <Row>
        <Col xs={12}>
          <Container fluid>
            <Row style={{marginBottom: 25, background: "#1B1A20"}} noGutters>
              <Col xs={"auto"}>
                <AlbumArt
                    src={!song ? transparent : !song.artworkUrl || song.artworkUrl.length === 0 ? imagePlaceHolder : song.artworkUrl}
                    onError={({currentTarget}) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = imagePlaceHolder;
                    }} />
              </Col>
              <Col className="d-flex align-items-center" style={{paddingLeft: 18, paddingRight: 80}}
                  xs={"auto"}>
                <Container fluid>
                  <Row>
                    <IconPlay color='white' onClick={e => {
                      e.stopPropagation();
                      this.playSong();
                    }} />
                    <SongName>{song.title}</SongName>
                  </Row>
                  <Row style={{marginTop: 7}}>
                    <Col xs={"auto"}
                        style={{paddingLeft: 0, paddingTop: 3, paddingRight: 3}}>
                      <ArtistName>{get(song, 'artist.name', '')}</ArtistName>
                    </Col>
                    <Col>
                      {explicitInfoRowRenderer(song)}
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col style={{paddingTop: 8}}>
                <Row className={"align-items-right justify-content-end"} style={{paddingRight: 15}}>
                  {this.props.activePlaylistId != -1 && (this.props.activeSongs[song.id] ? (
                      <IconCheck color='orange' style={{marginRight: 10}} onClick={e => {
                        e.stopPropagation();
                        this.handleRemoveSong()
                      }} />
                  ) : (
                      <IconPlus color='white' style={{marginRight: 10}} onClick={e => {
                        e.stopPropagation();
                        this.handleAddSong()
                      }} />
                  ))}
                  {
                      this.props.prevSongClicked &&
                      <>
                        <FontAwesomeIcon color={'white'}
                            style={{marginRight: 8}}
                            icon={['fas', 'chevron-left']}
                            onClick={this.props.prevSongClicked} />
                        <FontAwesomeIcon color={'white'}
                            style={{marginRight: '8px'}}
                            icon={['fas', 'chevron-right']}
                            onClick={this.props.nextSongClicked} />
                      </>
                  }
                  <ContextMenuTrigger
                      id="song_details_actions"
                      holdToDisplay={0}
                  >
                    <FontAwesomeIcon color={'white'}
                        style={{marginRight: '8px'}}
                        icon={['fas', 'bars']}
                    />
                  </ContextMenuTrigger>
                  <ContextMenu id="song_details_actions">
                    <MenuItem
                        onClick={() => this.setState({showConfirmArchiving: true})}>Archive</MenuItem>
                    <MenuItem onClick={this.props.moreFromSameArtistClicked}>More from same
                      Artist</MenuItem>
                  </ContextMenu>
                  {this.state.showConfirmArchiving && (
                      <ModalDecisionBuilder
                          title={"Are you sure you want to archive this song?"}
                          handleClose={this.closeDeleteConfirmationDialog}
                          handleAccept={this.doArchiving}
                      />
                  )}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <TabControl
                    selectedIndex={tabToIndex(this.state.selectedTab)}
                    controls={[{
                      title: "Playlists" + (this.props.playlists ? " (" + this.props.playlists.length + ")" : ""),
                      onClick: () => this.setState({selectedTab: TAB_PLAYLISTS}, () => {
                        sessionStorage.setItem("song_panel_selected_tab", TAB_PLAYLISTS);
                      })
                    }, {
                      title: `Metadata`,
                      onClick: () => {
                        this.setState({selectedTab: TAB_METADATA})
                        sessionStorage.setItem("song_panel_selected_tab", TAB_METADATA);
                      }
                    }, {
                      title: `Explicit`,
                      onClick: () => {
                        this.setState({selectedTab: TAB_EXPLICIT})
                        if (this.state.selectedTab !== TAB_EXPLICIT && !lyricsData) {
                          this.downloadLyrics();
                        }
                        sessionStorage.setItem("song_panel_selected_tab", TAB_EXPLICIT);
                      }
                    }]}
                />
              </Col>
            </Row>
            <Row style={{background: "#222129", borderRadius: 5}}>
              {(!this.state.selectedTab || this.state.selectedTab === TAB_PLAYLISTS) && playlists_tab}
              {this.state.selectedTab === TAB_METADATA && songmetadata_tab}
              {this.state.selectedTab === TAB_EXPLICIT && explicitForm_tab}
            </Row>
          </Container>

        </Col>
      </Row>
    </Container>
  }

  explicitWordsInfoRow() {
    return <Row style={{paddingBottom: 10}}>
      <Col style={{padding: 0, display: "flex"}}>
        <FontAwesomeIcon icon={"exclamation-triangle"}
            size={"xs"}
            style={{
              marginTop: 5,
              color: "#FFA500",
              marginRight: 5
            }} />
        <FormText style={{color: "#AEAEAE", marginTop: 3}}>Lyrics found:</FormText>
        <FormText style={{
          color: "#FF8989",
          marginLeft: 3,
          marginTop: 3
        }}>{this.state.lyricsData.foundExplicitWords.join(", ")}</FormText>
        <FormText style={{
          color: "#F8D686",
          marginTop: 3,
          marginLeft: 2
        }}>{this.state.lyricsData.foundAdultWords.join(", ")}</FormText>
      </Col>
    </Row>;
  }


}

const mapStateToProps = (state, props) => {
  const id = parseInt(props.id || props.match.params.id);
  const activePlaylistId = state.playlists.activePlaylistId;
  return {
    id,
    tab: props.location.hash,
    loading: state.songs.songPlaylists.loading,
    playlists: state.songs.songPlaylists.playlists,
    activePlaylistId,
    activeSongs: getPlaylistLookupView(state, activePlaylistId),
    allPlaylists: getPlaylists(state),
    song: state.songs.songDetails,
    userDetails: state.users.profileUser,
  }
}

const connectedSongDetailPanelTabbed = withRouter(connect(mapStateToProps)(SongDetailPanelTabbed));
export {
  connectedSongDetailPanelTabbed as SongDetailPanelTabbed
};

