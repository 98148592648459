import React, { useEffect, useState } from "react";
import { TaleaAudioPlayer } from "./AudioPlayer";
import { playerActions, playlistActions } from "../../_actions";

export const TaleaPlaybackController = (props) => {
  const {
    songId,
    source = '',
    title = '',
    artist = '',
    artworkUrl = '',
    loading,
    activePlaylistId,
    activeSongs,
    playlistPlayback,
    dispatch
  } = props;
  const [playlistIndex, setPlaylistIndex] = useState(0);
  const [currentSong, setCurrentSong] = useState(null);
  const [songs, setSongs] = useState(null);

  useEffect(() => {
    setPlaylistIndex(0)
    if (playlistPlayback) {
      const songs = playlistPlayback.songs;
      // move currentSong to the front of the array
     /* if (currentSong) {
        const currentSongIndex = songs.findIndex(s => s.id === currentSong.id);
        if (currentSongIndex > 0) {
          const currentSong = songs[currentSongIndex];
          songs.splice(currentSongIndex, 1);
          songs.unshift(currentSong);
        }
      }*/
      setSongs(songs);
      setCurrentSong(songs[0]);
    } else {
      setSongs(null);
    }

  }, [playlistPlayback]);

  const handleAddSong = () => {
    playlistActions.addSongs(dispatch, activePlaylistId, [currentSong.id])
  };

  const handleRemoveSong = () => {
    playlistActions.deleteSongs(dispatch, activePlaylistId, [currentSong.id])
  }

  if (playlistPlayback && songs) {
    const nextSong = async (skipCount = 1) => {
      if (playlistIndex + skipCount < songs.length) {
        let newPlaylistIndex;
        if (skipCount === 1) {
          newPlaylistIndex = playlistIndex + skipCount;
        } else {
          newPlaylistIndex = (Math.round((playlistIndex + skipCount) / 10) * 10) - 1;
        }
        setCurrentSong(songs[newPlaylistIndex]);
        playerActions.nextPlaylistSongPlaying(dispatch, songs[newPlaylistIndex].id);
        setPlaylistIndex(newPlaylistIndex);
      }
    }
    const prevSong = (skipCount = 1) => {
      if (playlistIndex - skipCount >= 0) {
        let newPlaylistIndex;
        if (skipCount === 1) {
          newPlaylistIndex = playlistIndex - skipCount;
        } else {
          newPlaylistIndex = (Math.round((playlistIndex - skipCount) / 10) * 10) - 1;
        }
        setCurrentSong(songs[newPlaylistIndex]);
        playerActions.nextPlaylistSongPlaying(dispatch, songs[newPlaylistIndex].id);
        setPlaylistIndex(newPlaylistIndex);
      }
    }
    let songTitles = songs.map(s => s.title);
    if (!currentSong || !songTitles.includes(currentSong.title)) {
      setCurrentSong(songs[playlistIndex]);
    }
    const songExistsInPinnedPlaylist = activeSongs[currentSong.id] !== undefined;
    return <TaleaAudioPlayer loading={loading} source={currentSong.songUrl} title={currentSong.title} artist={currentSong.artist.name}
        artworkUrl={currentSong.artworkUrl}
        playlistName={playlistPlayback && playlistPlayback.name}
        playlistIndex={playlistIndex}
        playlistId={playlistPlayback && playlistPlayback.id}
        playlistTotal={songs.length}
        nextSong={nextSong}
        prevSong={prevSong}
        handleAddSong={handleAddSong}
        handleRemoveSong={handleRemoveSong}
        activePlaylistId={activePlaylistId}
        songExistsInPinnedPlaylist={songExistsInPinnedPlaylist}
        songId={currentSong.id}
    />
  } else {
    if ((source !== '' && !currentSong) || (currentSong && currentSong.songUrl !== source)) {
      setCurrentSong({id: songId, songUrl: source, title: title, artist: {name: artist}, artworkUrl: artworkUrl});
    }
    const songExistsInPinnedPlaylist = activeSongs[songId];
    return <TaleaAudioPlayer loading={loading} source={source}
        title={title} artist={artist}
        artworkUrl={artworkUrl}
        handleAddSong={handleAddSong}
        handleRemoveSong={handleRemoveSong}
        songExistsInPinnedPlaylist={songExistsInPinnedPlaylist}
        activePlaylistId={activePlaylistId}
        songId={songId}
    />
  }
}