import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import get from 'lodash.get';
import React, {useState} from "react";
import styled from "styled-components";
import {DropdownFilter, Row} from '_components/Standard';
import {theme} from '_styles/Theme';
import {DefaultColumnContainer, DefaultRowContainer} from './Container.style';
import {DefaultLabel} from './Label.style';
import {PlayerConnectMenu} from "../_components";
import {Badge} from "react-bootstrap";
import {zonesActions} from "../_actions";
import imagePlaceHolder from '../_assets/images/talea-album-placeholder.png';


const SelectBox = styled(Row)`
  height: 65px;
  width: 342px;
  border: 1px solid #3D3D3D;
  border-radius: 3px;
  background-color: #222129;
  padding: 0px 21px;
  justify-content: space-between;
`;

const FormLabel = styled(DefaultLabel)`
  color: ${props => props.theme.formlabel};
  font-size: 14px;
  margin-bottom: 5px;
`;

const StatusLabel = styled.div`
  color: ${props => props.color};
  font-weight: bold;
`;

const ContentContainer = styled(DefaultColumnContainer)`
  color: white;
`;

const Column = styled(DefaultColumnContainer)`
  & + & {
    margin-top: 40px;
  }
`;

const LabelContainer = styled(DefaultRowContainer)`
  align-items: flex-start;
  margin-top: 10px;
  justify-content: space-between;
`;

const LabelButton = styled(DefaultLabel)`
  color: ${props =>
          !props.disabled ? props.theme.highlight : props.theme.darkGray};
  cursor: ${props => (!props.disabled ? "pointer" : "not-allowed")};

  & + & {
    margin-left: 10px;
  }
`;

const Image = styled.img`
  height: 165px;
  width: 165px;
`;

const StatusRow = styled(Row)`
  margin-top: 21px;

  & > * {
    margin-right: 8px;
  }
`;

const Dim = styled.div`
  color: ${theme.lightDim};
`;

const Unassigned = styled.div`
  font-style: italic;
  color: ${theme.dim};

  &:before {
    content: 'Unassigned';
  }
`;

const NowPlayingBox = styled(Row)`
  display: flex;
  margin-top: 38px;
  height: 166px;
  border-radius: 5px;
  background-color: #222129;
`;

const NowPlayingSub = styled(Column)`
  flex: 1 0 auto;
  padding-top: 18px;
  padding-left: 18px;
  height: 100%;
`;

const NowPlayingContext = styled.div`
  color: #959499;
  font-size: 14px;
  margin-bottom: 18px;
`;

const NowPlayingTitle = styled.div`
  font-size: 24px;
  margin-bottom: 5px;
`;

const OptionClick = styled.div`
  pointer: cursor;
`;

const OptionButton = ({onClick}) => {
    const handleClick = e => {
        e.stopPropagation()
        onClick();
    }

    return (
        <OptionClick onClick={handleClick}><FontAwesomeIcon icon={['fal', 'ellipsis-v']} fixedWidth/></OptionClick>
    )
}


const getOptions = list => list.map(item => ({id: item.id, text: item.name, ref: item}))
const getOptionsExtra = list => [({id: -1, text: 'Unassign', ref: undefined})].concat(getOptions(list));

export const ZoneSummaryView = ({
                                    zone,
                                    dispatch,
                                    players,
                                    schedules,
                                    onScheduleSelect,
                                    onPlayerSelect,
                                    onPlayerHistoryClicked,
                                    onScheduleOpen,
                                    onPlayerOpen
                                }) => {
    const [schedule, setSchedule] = useState(zone.schedule ? zone.schedule : undefined);
    const [player, setPlayer] = useState(zone.player ? zone.player : undefined);
    const [hasForcedPlaylist, setHasForcedPlaylist] = useState(zone.forcedPlaylist ? true : false);

    const paired = zone.player && zone.schedule;

    const scheduleSelect = selected => {
        setSchedule(selected.ref);
        onScheduleSelect(selected.ref);
    }

    const playerSelect = selected => {
        if (zone.player && selected.id !== -1 && selected.id !== zone.player.id) {
            // "To be safe"
            alert("Cannot assign a new player until unassigning the player and saving");
            return false;
        }

        setPlayer(selected.ref);
        onPlayerSelect(selected.ref);
    }

    const handleScheduleOpen = () => {
        onScheduleOpen(schedule);
    }

    const handlePlayerOpen = () => {
        onPlayerOpen(player);
    }

    const scheduleIconClickHandler = e => {
        e.stopPropagation();
        onScheduleOpen(schedule);
    }

    const getPlayerStatusDisplay = () => {
        if (get(player, 'id', 0) === get(zone, 'player.id', 0)) return get(zone, 'player.status', 'none');
        return 'none';
    }

    const handleReturnToSchedule = () => {
        zonesActions.removeForcedPlaylist(dispatch, zone.id);
        setHasForcedPlaylist(false);
    }

    const ReturnToScheduleSpan = styled.span`
      margin-left: 10px;
      cursor: pointer;
      color: #B9B9B9C7;
    `;

    return (
        <ContentContainer>
            <Row>
                <FormLabel>SCHEDULE</FormLabel>
                <div style={{width: '320px'}}/>
                <FormLabel>PLAYER</FormLabel>
            </Row>
            <Row>
                <DropdownFilter menuStyle={{width: '400px'}} onSelect={scheduleSelect}
                                selectedId={schedule ? schedule.id : -1} options={getOptionsExtra(schedules)}
                                component={
                                    <SelectBox>
                                        <Row>
                                            <FontAwesomeIcon icon="calendar-alt" size='lg' opacity={.16}
                                                             style={{marginRight: 16}}
                                                             onClick={schedule ? scheduleIconClickHandler : undefined}/>
                                            {schedule ? schedule.name : <Unassigned/>}
                                        </Row>
                                        <OptionButton onClick={handleScheduleOpen}/>
                                    </SelectBox>
                                }/>
                <FontAwesomeIcon size='2x' icon='arrow-alt-right' color={'#66646F'} style={{width: '52px'}}/>
                <DropdownFilter menuStyle={{width: '400px'}} onSelect={playerSelect}
                                selectedId={player ? player.id : -1} options={getOptionsExtra(players)} component={
                    <SelectBox>
                        <Row>
                            <div style={{marginRight: '16px'}}
                                 className={`dot-status dot-status-${getPlayerStatusDisplay()}`}/>
                            {player ? player.name : <Unassigned/>}
                        </Row>
                        {player ? <OptionButton onClick={handlePlayerOpen}/> : null}
                    </SelectBox>
                }/>
            </Row>
            <StatusRow>
                {paired ? (
                    <>
                        <FontAwesomeIcon icon='check' color={theme.onlineGreen}/>
                        <StatusLabel color={theme.onlineGreen}>Paired</StatusLabel>
                        <Dim>Player is linked to schedule and is playing.</Dim>
                    </>
                ) : (
                    <>
                        <FontAwesomeIcon size='lg' icon={['fal', 'cauldron']}/>
                        <StatusLabel>Unpaired</StatusLabel>
                        <Dim>Specify a schedule and player to pair.</Dim>
                    </>
                )}
            </StatusRow>

            {(zone.player !== undefined && (zone.currentSong !== undefined || hasForcedPlaylist) &&
                <NowPlayingBox>
                    <NowPlayingSub>
                        <NowPlayingContext>NOW PLAYING</NowPlayingContext>
                        {zone.currentSong && <>
                            <NowPlayingTitle>{zone.currentSong.title}</NowPlayingTitle>
                            <div>{get(zone, "currentSong.artist.name", '')}</div>
                            <div>Playlist: {get(zone, "currentSong.playlistName", '')}</div>
                        </>}
                        {hasForcedPlaylist && <div style={{marginTop: 30}}>
                            <Badge style={{backgroundColor: "white", color: "black"}}>Playlist Override</Badge>
                            <ReturnToScheduleSpan
                                onClick={handleReturnToSchedule}><FontAwesomeIcon
                                icon={"arrow-circle-left"}></FontAwesomeIcon> Return to Schedule</ReturnToScheduleSpan>
                        </div>}
                    </NowPlayingSub>
                    { zone.currentSong && <Image src={zone.currentSong.artworkUrl?zone.currentSong.artworkUrl:imagePlaceHolder} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src=imagePlaceHolder;
                    }}/>}
                </NowPlayingBox>
            )}
            <LabelContainer>
                <LabelButton disabled={false} onClick={() => onPlayerHistoryClicked(zone.id)}>View Zone Play
                    History</LabelButton>
            </LabelContainer>
            {zone.player && <LabelContainer>
                <PlayerConnectMenu playerId={zone.player.id}
                                   sshConnected={zone.player.sshConnected}
                > </PlayerConnectMenu>
            </LabelContainer>
            }
        </ContentContainer>
    );
};
