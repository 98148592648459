import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import get from 'lodash.get';
import React, { createRef, useContext, useEffect, useState } from "react";
import { ReactReduxContext } from "react-redux";
import { BehaviorSubject } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import styled from "styled-components";
import { Loading } from '_styles';
import VirtualList from './VirtualList';
import { getFocus } from '../../_reducers/ui.reducer';
import { uiActions } from '../../_actions';
import { WidthContext, WidthWatcher } from './WidthWatcher';
import ReactTooltip from "react-tooltip";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";


const TableRowBase = styled.div``;

const ButtonIcon = styled(FontAwesomeIcon)`
    color: white;
`;

const TableHeader = styled(TableRowBase)`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 42px;
    color: white;
`;

const ReactTooltipStyled = styled(ReactTooltip)`
    background: black !important;
    color: white !important;
    border: 1px solid grey !important;

    .place-left::after {
        border-left: 8px solid grey !important;
    }
`

const composite = (current, next) => {
  if (!current) return next;

  const type = typeof next;
  switch (type) {
    case 'function':
      return e => {
        current(e);
        next(e);
      }
    case 'string':  // classname
      return `${current} ${next}`
    case 'object':
      return Object.assign({}, current, next);
    default:
      console.error("Cannot compose type", type, current, next);
      return next;
  }
}

const compositeObject = (current, next) => {
  let c = {...current};
  for (let p in next) {
    if (!c[p]) c[p] = next[p];
    else c[p] = composite(current[p], next[p]);
  }
  return c;
}

const addProperty = (obj, index, value) => ({...obj, [index]: value});
const deleteProperty = ({[key]: _, ...newObj}, key) => newObj;

const updateSelectedState = (state$, selected, selectionIndex) => {
  state$.next({selected, selectionIndex});
}

class HistoryDecorator {
  constructor(row) {
    this.row = row;
  }

  setup = () => {
  }

  close = () => {
  }

  getProps = () => {
    return {
      "data-id": this.row.id,
      className: this.row.changeAction === "ADD" ? 'history-added' :
          this.row.changeAction === "REMOVE" ? 'history-removed' : ""
    }
  }

}

class SelectionDragDecorator {
  constructor(store, state$, id, rows, onDelete = undefined) {
    this.store = store;
    this.rows = rows;
    this.songId = id;
    this.state$ = state$;
    this.onDelete = onDelete;
  }

  setup = update => {
    this.subscription = this.state$.pipe(skip(1)).subscribe(update);
    if (this.onDelete) window.addEventListener('keydown', this.handleKeyDown);
  }

  close = () => {
    if (this.onDelete) window.removeEventListener('keydown', this.handleKeyDown);
    this.subscription.unsubscribe();
  }

  getSelected = () => Object.values(this.state$.getValue().selected);

  handleKeyDown = e => {
    // Only the focused table will process keydown
    const focus = getFocus(this.store.getState());
    if (focus !== this.state$) return;
    if (e.target.type === 'text' || e.target.type === 'textarea') {
      // prevent if text input or text area
      return;
    }

    if (e.key === 'Delete' || e.key === 'Backspace') {
      const songs = this.getSelected();
      if (songs.length > 0) this.onDelete(songs);
    }
  }

  onDragStart = e => {
    e.dataTransfer.effectAllowed = 'copy';
    e.dataTransfer.dropEffect = 'copy';

    // Empty image
    let img = document.createElement('img');
    e.dataTransfer.setDragImage(img, -10, -10);

    // If started drag on a non-selected item, drag just that element
    const id = parseInt(e.currentTarget.dataset.id);
    const selected = this.isSelected(id);
    if (!selected) {
      const index = this.rows.findIndex(row => row.id === id);
      const row = this.rows[index];
      updateSelectedState(this.state$, {[id]: row}, index);
    }

    // Package song data for drag transfer
    e.dataTransfer.setData('application/songs', JSON.stringify(this.getSelected()));
  }

  onClick = e => {
    const {selected, selectionIndex} = this.state$.getValue();

    const id = parseInt(e.currentTarget.dataset.id);
    const index = this.rows.findIndex(row => row.id === id);
    const row = this.rows[index];

    // When a row is selected, make this the keyboard focus target
    // TODO remove out of this class
    uiActions.setFocus(this.store.dispatch, this.state$);

    // Range selection
    if (e.shiftKey && selectionIndex !== -1) {
      const start = Math.min(index, selectionIndex);
      const end = Math.max(index, selectionIndex);

      let ids = {...selected};
      for (let i = start; i <= end; i++) {
        const row = this.rows[i];
        ids[row.id] = row;
      }

      updateSelectedState(this.state$, ids, index);
      return;
    }

    // Spot selection
    if (e.metaKey || e.altKey || e.ctrlKey) {
      const ids = selected[id] ?
          deleteProperty(selected, id) :
          addProperty(selected, id, row);

      updateSelectedState(this.state$, ids, index);
      return;
    }

    // No modifier click
    updateSelectedState(this.state$, {[id]: row}, index);
  }

  isSelected = () => {
    return this.state$.getValue().selected[this.songId];
  }

  getProps = () => {
    return {
      "data-id": this.songId,
      draggable: true,
      onDragStart: this.onDragStart,
      onClick: this.onClick,
      ...(this.isSelected() ? {className: 'highlight'} : {})
    }
  }
}

// class SongPlayingDecorator {
//   constructor(store, songId) {
//     this.store = store;
//     this.songId = songId;
//   }
//
//   setup = update => {
//     this.subscription = from(this.store).pipe(
//       map(state => state.player.songId),
//       skip(1),
//       distinctUntilChanged()
//     ).subscribe(update);
//   }
//
//   close = () => {
//     this.subscription.unsubscribe();
//   }
//
//   currentSongId = () => {
//     return this.store.getState().player.songId;
//   }
//
//   getProps = () => {
//     return this.songId !== this.currentSongId() ? {} : {
//       className: 'song_playing'
//     };
//   }
// }

// Strategy pattern
// Decorators must implement setup => update, close & getProps
class BaseTableRow extends React.Component {
  state = {
    compositeProps: {}
  }

  componentDidMount() {
    this.setupDecorators();
  }

  componentDidUpdate(prevProps) {
    if (this.props.decorators === prevProps.decorators) return;
    this.cleanupDecorators(prevProps);
    this.setupDecorators();
  }

  componentWillUnmount() {
    this.cleanupDecorators(this.props);
  }

  setupDecorators = () => {
    this.props.decorators.forEach((d, i) => d.setup(() => this.update(i)));
    this.propList = this.props.decorators.map(d => d.getProps())
    this.compositePropsAndUpdate();
  }

  cleanupDecorators = (props) => props.decorators.forEach(d => d.close());

  update = (index) => {
    this.propList[index] = this.props.decorators[index].getProps();
    this.compositePropsAndUpdate();
  }

  compositePropsAndUpdate = () => {
    let props = this.props;
    this.propList.forEach(c => props = compositeObject(props, c));

    this.setState({
      compositeProps: props
    })
  }

  render() {
    return (
        <div {...this.state.compositeProps}>
                {this.props.children}
            </div>
    )
  }
}

const TableRow = styled(BaseTableRow)`
    display: flex;
    flex: 1 0 auto;
    height: 42px;
    flex-direction: row;
    align-items: center;
`;

const TableCellBase = styled.div`
    display: ${props => props.listwidth <= props.collapse ? 'none' : 'flex'};

    ${props => props.fixed ? `
    flex: 0 0 ${props.fixed}px;
  ` : props.percent ? `
    flex: ${props.percent} 0;
  ` : ''}
`;

const TableHeadCell = styled(TableCellBase)`
    height: 20px;
    cursor: pointer;
    user-select: none;
    flex-direction: row;
    overflow: hidden;
    text-overflow: ellipsis;

`;

const SortHeader = styled.div`
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 14px;
    color: ${props => props.sorting ? 'white' : '#686868'};

    &:hover {
        color: white;
    }
`;

// Commonly used
export const Icon = styled(FontAwesomeIcon)`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    transition: all 250ms ease-out;
`;

export const IconHover = styled(Icon)`
    &:hover {
        color: white;
        opacity: 1;
    }
`;

export const IconInfo = props => <IconHover icon={['fas', 'info-circle']} {...props} />;
export const IconNotes = props => <Icon icon={['fas', 'notes-medical']} {...props} />;
export const IconPlus = props => <Icon icon={['fal', 'plus']} {...props} />;
export const IconCheck = props => <Icon icon={['fal', 'check']} {...props} />;
export const IconPlay = props => <Icon icon={['fas', 'play']} {...props} />;

const SORT_STATE_NONE = 0;
const SORT_STATE_ASC = 1;
const SORT_STATE_DESC = 2;

const isEllipsisActive = (e) => {
  return e.offsetHeight < e.scrollHeight || e.offsetWidth < e.scrollWidth;
}

const BlitzCell = ({row, col, width, index}) => {
  const divRef = createRef();
  const [showTooltip, setShowTooltip] = useState(false);
  let classes = "table_cell ";
  if (col.nocell) classes = "";
  if (col.dim) classes += "table_cell_dim ";
  if (width <= col.collapse) classes += "table_cell_hide ";
  let style = col.fixed ? {
    flex: `0 0 ${col.fixed}px`
  } : col.percent ? {
    flex: `${col.percent} 0`
  } : {};
  const hasRenderer = col.rowRenderer;
  const value = hasRenderer ? col.rowRenderer(row, index) : get(row, col.field);
  const cellId = (row.id ? row.id : row.idField ? row[row.idField] : Math.random() * 10000) + Math.random() * 10000 + col.field;
  if (!col.noTooltip) {
    useEffect(() => {
      if (divRef && divRef.current) {
        const currentDiv = divRef.current;
        setTimeout(() => {
          if (isEllipsisActive(currentDiv)) {
            setShowTooltip(true);
          }
        }, 100);
      }
    }, [divRef]);
  }
  return (
      <>
        <div ref={divRef} data-event='mouseover' data-event-off="click mouseleave scroll" data-iscapture='true' data-tip
            data-for={"tableCell" + cellId}
            className={classes} key={col.field || index}
            style={style}>
                {value}
            </div>
        {(!col.noTooltip && showTooltip) &&
            <ReactTooltipStyled delayShow={1500} positionStrategy="fixed" effect='solid' place="bottom" type='light'
                id={"tableCell" + cellId}>
       {value}
                    </ReactTooltipStyled>}
        </>
  )
}

const Columnizer = ({row, columns}) => {
  const width = useContext(WidthContext)
  return columns.map((col, index) => {
        return <BlitzCell key={col.field || index} col={col} row={row} index={index} width={width} />
      }
  )
}

const useStream = (behaviorSubject, onNextFilter = undefined) => {
  const [state, setState] = useState(behaviorSubject.getValue());

  useEffect(() => {
    setState(behaviorSubject.getValue());
    const sub = behaviorSubject.subscribe(value => {
      if (!onNextFilter || onNextFilter(value)) setState(value);
    });
    return () => sub.unsubscribe();
  }, [behaviorSubject]);

  return state;
}

const buildSortState = (sortField, sortMode, isNumber, sortFunction) => ({
  sortField, sortMode, isNumber, sortFunction
});

const ColumnizerHeader = ({
                            columns,
                            hideColumn,
                            showColumn,
                            hiddenColumns,
                            resetHiddenColumns,
                            disableColumnFiltering,
                            style,
                            sortStateStream: sortState$,
                            onSortSelectionChanged,
                            ...props
                          }) => {
  const state = useStream(sortState$)

  const handleSort = e => {
    const index = e.currentTarget.dataset.index;
    const col = columns[index];
    const field = col.field;
    const isNumber = col.isNumber || false;

    // Advance sort state
    const s = sortState$.getValue();
    let sortMode = s.sortMode;
    if (s.sortField === field) {
      sortMode = (sortMode + 1) % 3;
    } else {
      sortMode = SORT_STATE_ASC;
    }

    if (!field || field === '') {
      sortState$.next(buildSortState(undefined, SORT_STATE_NONE, false));
      return;
    }

    sortState$.next(buildSortState(field, sortMode, isNumber, col.sortFunction));
    if (onSortSelectionChanged) {
      onSortSelectionChanged(field, sortMode);
    }
  }

  const width = useContext(WidthContext);

  return (

      <div style={{position: "relative"}}>
        {hiddenColumns.length > 0 &&
            <ButtonIcon style={{position: 'absolute', marginLeft: 12, marginTop: 12, color: '#979797'}} onClick={
              resetHiddenColumns
            } size="sm" icon="undo" />
        }
        <TableHeader style={style}>
        {columns.map((col, index) => {
          const {name, title, field} = col;
          const isSorting = state.sortField === field && state.sortMode !== SORT_STATE_NONE;
          const hasField = !!field;
          return (
              <>
                  <TableHeadCell key={field || index} listwidth={width} {...col}
                      title={(col.textTitle ? col.textTitle : col.title)} data-index={index}
                      onClick={hasField ? handleSort : () => {
                      }}>
                        {hasField && (
                            <>
                           <ContextMenuTrigger
                               id={"filter_column_menu_" + name}
                               mouseButton={2}
                           >
                                <SortHeader sorting={isSorting}>{title}</SortHeader>
                             </ContextMenuTrigger>
                              {(state.sortField === field && state.sortMode === SORT_STATE_ASC) &&
                                  <FontAwesomeIcon icon={['fal', 'chevron-up']} style={{marginLeft: '6px'}} />}
                              {(state.sortField === field && state.sortMode === SORT_STATE_DESC) &&
                                  <FontAwesomeIcon icon={['fal', 'chevron-down']} style={{marginLeft: '6px'}} />}

                           </>
                        )}

                    </TableHeadCell>

                {!disableColumnFiltering && <ContextMenu id={"filter_column_menu_" + name}>
                          <MenuItem onClick={() => {
                            hideColumn(col.name);
                          }}>Hide {col.name.toUpperCase()}</MenuItem>
                  {hiddenColumns && hiddenColumns.map((col, index) => {
                    return (
                        <MenuItem key={index} onClick={() => {
                          showColumn(col.name);
                        }}>Show {col.name.toUpperCase()}</MenuItem>)
                  })}
              </ContextMenu>}

                </>

          )
        })}

            </TableHeader>
          </div>
  );
}

const SortedHeader = ({
                        children,
                        tableName,
                        columns,
                        hideColumn,
                        showColumn,
                        hiddenColumns,
                        resetHiddenColumns,
                        disableColumnFiltering,
                        rows,
                        style,
                        sortStateStream: sortState$,
                        onSorted,
                        onSortSelectionChanged,
                        ...props
                      }) => {
  const [sortedRows, setSortedRows] = useState([...rows]);

  useEffect(() => {
    if (onSorted) {
      onSorted(sortedRows);
    }
  }, [sortedRows]);

  useEffect(() => {
    // setSortedRows(rows);
    const sub = sortState$.subscribe(state => {
      if (!rows || rows.length === 0) {
        setSortedRows([]);
        return;
      }

      // Sort the rows based on state
      if (state.sortMode === SORT_STATE_NONE) {
        setSortedRows(rows);
      } else {
        const isAsc = state.sortMode === SORT_STATE_ASC;
        const field = state.sortField;

        const evl = typeof (field) === "function" ? field :
            state.isNumber ? row => get(row, field, 0) : row => get(row, field, '');


        const sortFunc = state.sortFunction ? (a, b) =>
                state.sortFunction(a, b, isAsc) :
            state.isNumber ?
                (isAsc ? (a, b) => evl(a) - evl(b) : (a, b) => evl(b) - evl(a))
                :
                (isAsc ? (a, b) => evl(a) != null && evl(b) != null && evl(a).localeCompare(evl(b)) : (a, b) => evl(a) != null && evl(b) != null && evl(b).localeCompare(evl(a)));
        // Copy to prevent modifying the original
        const rowsSorted = [...rows].sort(sortFunc)
        setSortedRows(rowsSorted);
      }
    });
    return () => sub.unsubscribe();
  }, [rows, sortState$]);


  return (
      <>
        <ContextMenuTrigger id={"table_context_menu" + tableName} mouseButton={2}>
        <ColumnizerHeader sortStateStream={sortState$}
            hideColumn={hideColumn}
            showColumn={showColumn}
            hiddenColumns={hiddenColumns}
            resetHiddenColumns={resetHiddenColumns}
            disableColumnFiltering={disableColumnFiltering}
            columns={columns} style={style}
            onSortSelectionChanged={onSortSelectionChanged} />
        </ContextMenuTrigger>
        {
          children({
            ...props,
            sortedRows
          })
        }
</>
  )
}

const buildDefaultSortState = (defaultSortField, columns, sortState) => {
  let sortStateValue = !sortState ? SORT_STATE_NONE : sortState === "desc" ? SORT_STATE_DESC : SORT_STATE_ASC;
  const col = columns.find(c => c.field === defaultSortField);
  if (!defaultSortField || !col) return buildSortState(undefined, sortStateValue, false);

  if (!col) throw new Error(`Default sort column '${defaultSortField}' not found`);
  const sortFunction = col.sortFunction;

  sortState = buildSortState(defaultSortField, sortStateValue, !!col.isNumber, sortFunction);
  return sortState;
}

const useEffectSelectAll = (state$, rows) => {
  useEffect(() => {
    const keyDown = e => {
      if (e.key === 'a' && (e.metaKey || e.ctrlKey)) {
        let selected = {}
        rows.forEach(row => selected[row.id] = row);
        updateSelectedState(state$, selected, -1);

        e.preventDefault();
      }
    }
    window.addEventListener('keydown', keyDown);
    return () => window.removeEventListener('keydown', keyDown);
  }, [rows]);
}

export const SortTable = ({
                            tableName,
                            loading = false,
                            rows,
                            columns,
                            disableColumnFiltering = false,
                            gutter = 0,
                            onRowClick,
                            onRowDoubleClick,
                            onSelection,
                            defaultSortOrder,
                            defaultSortField,
                            onDelete,
                            onSorted,
                            ...props
                          }) => {
  if (loading) return <Loading />;

  for (const col of columns) {
    if (!col.name) {
      if (col.title && (typeof col.title === 'string' || col.title instanceof String)) {
        col.name = col.title;
      }
      if (!col.name) {
        col.name = "";
      }
    }
  }

  const loadHiddenColumns = () => {
    const hiddenColumnsNamesValue = sessionStorage.getItem(tableName + '_hidden_columns');
    if (!hiddenColumnsNamesValue) return [];
    const hiddenColumnsNames = JSON.parse(hiddenColumnsNamesValue);
    return columns.filter(col => hiddenColumnsNames.includes(col.name));
  }

  const loadFilteredColumns = () => {
    const hiddenColumns = sessionStorage.getItem(tableName + '_hidden_columns');
    if (!hiddenColumns) return columns;
    const hiddenColumnsNames = JSON.parse(hiddenColumns);
    const filteredColumns = columns.filter(col => !hiddenColumnsNames.includes(col.name));
    return filteredColumns;
  }

  const initialState = loadFilteredColumns();
  const [filteredColumns, setFilteredColumns] = useState(initialState);
  const [hiddenColumns, setHiddenColumns] = useState(loadHiddenColumns());

  const resetHiddenColumns = () => {
    setHiddenColumns([]);
    setFilteredColumns([...columns]);
  }

  const hideColumn = (name) => {
    setFilteredColumns(filteredColumns.filter(col => col.name !== name));
    // set columns to be hidden in the same order as original columns
    const newHiddenColumns = [...hiddenColumns];
    const columnToHide = columns.find(col => col.name === name);
    newHiddenColumns.push(columnToHide);
    newHiddenColumns.sort((a, b) => {
      const indexA = columns.findIndex(column => column.name === a.name);
      const indexB = columns.findIndex(column => column.name === b.name);
      return indexA - indexB;
    })
    setHiddenColumns(newHiddenColumns);
  }

  const showColumn = (name) => {
    // set columns to be visible in the same order as original columns
    const newFilteredColumns = [...filteredColumns];
    newFilteredColumns.push(columns.find(col => col.name === name));
    newFilteredColumns.sort((a, b) => {
      const indexA = columns.findIndex(column => column.name === a.name);
      const indexB = columns.findIndex(column => column.name === b.name);
      return indexA - indexB;
    });

    setFilteredColumns(newFilteredColumns);
    // set columns to be hidden in the same order as original columns
    const newHiddenColumns = [...hiddenColumns];
    setHiddenColumns(newHiddenColumns.filter(col => col.name !== name));
  }

  useEffect(() => {
    saveHiddenColumns()
  }, [hiddenColumns]);

  const saveHiddenColumns = () => {
    if (!hiddenColumns || hiddenColumns.length === 0) {
      sessionStorage.removeItem(tableName + '_hidden_columns');
    } else {
      sessionStorage.setItem(tableName + '_hidden_columns', JSON.stringify(hiddenColumns.map(col => (col.name))));
    }
  }


  let sortOrder = defaultSortOrder;
  let sortField = defaultSortField;
  const storedSortOrder = tableName && sessionStorage.getItem(tableName + '_sort_mode');
  const storedSortField = tableName && sessionStorage.getItem(tableName + '_sort_field');
  if (storedSortOrder && storedSortField) {
    sortOrder = storedSortOrder;
    sortField = storedSortField;
  }

  if (hiddenColumns.find(col => col.field === sortField)) {
    sortOrder = null;
    sortField = null;
    sessionStorage.removeItem(tableName + '_sort_mode');
    sessionStorage.removeItem(tableName + '_sort_field');
  }

  const onSortSelectionChanged = (sortField, sortMode) => {
    if (sortMode === 0) {
      sessionStorage.removeItem(tableName + '_sort_mode');
      sessionStorage.removeItem(tableName + '_sort_field');
    } else {
      sessionStorage.setItem(tableName + '_sort_mode', sortMode === 2 ? "desc" : "asc");
      sessionStorage.setItem(tableName + '_sort_field', sortField);
    }
  }

  const [sortState$] = useState(new BehaviorSubject(buildDefaultSortState(sortField, filteredColumns, sortOrder)));
  const [selection$] = useState(new BehaviorSubject({selected: {}, selectionIndex: -1}));

  useEffectSelectAll(selection$, rows);

  useEffect(() => {
    // Cannot conditionally create effects
    if (!onSelection) return () => {
    }

    // Listen to selection updates
    const sub = selection$.pipe(skip(1), map(state => Object.values(state.selected))).subscribe(onSelection);
    return () => sub.unsubscribe();
  });

  return (
      <ReactReduxContext.Consumer>
            {({store}) => (
                <WidthWatcher>
                      <SortedHeader columns={filteredColumns}
                          hideColumn={hideColumn}
                          showColumn={showColumn}
                          tableName={tableName}
                          disableColumnFiltering={disableColumnFiltering}
                          resetHiddenColumns={resetHiddenColumns}
                          hiddenColumns={hiddenColumns}
                          rows={rows} sortStateStream={sortState$}
                          onSortSelectionChanged={onSortSelectionChanged}
                          style={{padding: `0 ${gutter}px`}}
                          onSorted={onSorted}>
                        {({sortedRows}) => (
                            <VirtualList className='virtual-list' rows={sortedRows} fixedRowHeight={42} noCache={props.noCache}
                                rowRenderer={row => (
                                    <TableRow
                                        key={row.id ? row.id : row.idField ? row[row.idField] : Math.random() * 100000}
                                        className='table_hover_padding'
                                        style={{padding: `0 ${gutter}px`}}
                                        onClick={onRowClick ? () => onRowClick(row) : undefined}
                                        onDoubleClick={onRowDoubleClick ? () => onRowDoubleClick(row) : undefined}
                                        decorators={[
                                          //  new SongPlayingDecorator(store, row.id),
                                          ...props.historyDecorator ? [new HistoryDecorator(row)] : [],
                                          ...onSelection ? [new SelectionDragDecorator(store, selection$, row.id, sortedRows, onDelete)] : []
                                        ]}
                                    >
                                                 <Columnizer id={row.id} row={row} columns={filteredColumns}
                                                     showTooltips={props.showTooltips} />
                                             </TableRow>
                                )} />
                        )}
                    </SortedHeader>

                  {!disableColumnFiltering && <ContextMenu id={"table_context_menu" + tableName}>
                    {filteredColumns.map((col, index) => {
                      return (
                          <MenuItem attributes={{style: {background: ""}}} key={index} onClick={() => {
                            hideColumn(col.name);
                          }}>- Hide {col.name.toUpperCase()}</MenuItem>)

                    })}
                    {hiddenColumns.map((col, index) => {
                      return (
                          <MenuItem key={index} onClick={() => {
                            showColumn(col.name);
                          }}>+ Show {col.name.toUpperCase()}</MenuItem>)
                    })}
                  </ContextMenu>}
                </WidthWatcher>
            )}
        </ReactReduxContext.Consumer>
  );
}
