import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { TALEA_ENVIRONMENT } from "../_services/api";
import { Row } from "./Standard";
import { CloudDownload, DeleteForever } from "@material-ui/icons";
import { DefaultButton } from "../_styles";
import { Modal } from "react-bootstrap";
import { playerActions } from "../_actions";

export function PlayerConnectMenu(props) {
  const [showDownloadFileModal, setDownloadFileModal] = useState(false);
  const [showRemoveUnusedSongsModal, setShowRemoveUnusedSongsModal] = useState(false);
  const [file, setFile] = useState("");

  const downloadItemClicked = () => {
    setDownloadFileModal(true);
  }
  const removeUnusedSongsClicked = () => {
    setShowRemoveUnusedSongsModal(true);
  }

  function performRemoveUnusedSongs(playerId) {
    playerActions.removeUnusedSongs(playerId);
    setShowRemoveUnusedSongsModal(false);

  }

  const buttonColor = props.sshConnected === "CONNECTED" ? "#0DB55B" :
      props.sshConnected === "DISCONNECTED" ? props.playerOnline ? "#FF0000" : "#656363" : "#656363";
  const buttonTextColor = props.sshConnected === "CONNECTED" ? "#000000" :
      props.sshConnected === "DISCONNECTED" ? props.playerOnline ? "#FFFFFF" : "#FFFFFF" : "#FFFFFF";
  return <>
        <Modal style={{color: 'black'}} show={showDownloadFileModal} backdrop={true} size={'small'}
            onHide={() => {
              setDownloadFileModal(false);
            }}>
            <Modal.Header style={{
              backgroundColor: "#F5F5F5",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }} closeButton>
                <Modal.Title style={{width: "90%", paddingLeft: "29%"}}>Download file</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{backgroundColor: "#F5F5F5"}}>
                <Row style={{marginBottom: 40}}>
                    <CloudDownload style={{fontSize: 40, margin: "auto"}} />
                </Row>
                <Row style={{marginBottom: 40}}>
                    <textarea onChange={(event) => setFile(event.target.value)}
                        value={file}
                        placeholder={"File..."}
                        style={{height: 100, width: "90%", margin: "auto", padding: 20}}></textarea>
                </Row>
                <Row>
                    <DefaultButton
                        style={{width: 150, border: 0, color: "black", backgroundColor: "#DFDFDF", margin: "auto"}}
                        onClick={() => {
                          window.open("taleaSSH://filedownload_" + props.playerId + "@" + TALEA_ENVIRONMENT + "_" + file);
                          setDownloadFileModal(false);
                        }}>
                        Download
                    </DefaultButton>
                </Row>
            </Modal.Body>
        </Modal>
        <Modal style={{color: 'black'}} show={showRemoveUnusedSongsModal} backdrop={true} size={'small'}
            onHide={() => {
              setShowRemoveUnusedSongsModal(false);
            }}>
            <Modal.Header style={{
              backgroundColor: "#F5F5F5",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }} closeButton>
                <Modal.Title style={{width: "90%", paddingLeft: "20%"}}>Remove Unused Songs</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{backgroundColor: "#F5F5F5"}}>
                <Row style={{marginBottom: 40}}>
                    <DeleteForever style={{fontSize: 40, margin: "auto"}} />
                </Row>
                <Row style={{marginBottom: 40}}>
                    <p>Are you sure you want to remove the unused songs from the player?</p>
                </Row>
                <Row>
                    <DefaultButton
                        style={{width: 150, border: 0, color: "black", backgroundColor: "#DFDFDF", margin: "auto"}}
                        onClick={() => {
                          setShowRemoveUnusedSongsModal(false);
                        }}>
                        Cancel
                    </DefaultButton>
                    <DefaultButton
                        style={{width: 150, border: 0, color: "black", backgroundColor: "#DFDFDF", margin: "auto"}}
                        onClick={() => {
                          performRemoveUnusedSongs(props.playerId);
                        }}>
                        Ok
                    </DefaultButton>
                </Row>
            </Modal.Body>
        </Modal>
        <Dropdown>
            <Dropdown.Toggle
                style={{
                  color: buttonTextColor,
                  backgroundColor: buttonColor,
                  borderColor: "white",
                  borderRadius: "1rem",
                  lineHeight: 1
                }}>
                Connect
            </Dropdown.Toggle>
            <Dropdown.Menu style={{backgroundColor: "#73a47", position: "fixed"}}>
                <Dropdown.Item
                    href={"taleaSSH://connect_" + props.playerId + "@" + TALEA_ENVIRONMENT}>Connect</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                    href={"taleaSSH://logs_" + props.playerId + "@" + TALEA_ENVIRONMENT + "_mpd"}>MPD
                    Logs</Dropdown.Item>
                <Dropdown.Item
                    href={"taleaSSH://logs_" + props.playerId + "@" + TALEA_ENVIRONMENT + "_talea"}>Talea
                    Logs</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={downloadItemClicked}>Download file...</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                    href={"taleaSSH://restart_" + props.playerId + "@" + TALEA_ENVIRONMENT}>Restart
                    app</Dropdown.Item>
                <Dropdown.Item
                    onClick={removeUnusedSongsClicked}>Remove unused songs
                </Dropdown.Item>
                <Dropdown.Item
                    href={"taleaSSH://reboot_" + props.playerId + "@" + TALEA_ENVIRONMENT}>Reboot
                    device</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                    href={"taleaSSH://mpcstatus_" + props.playerId + "@" + TALEA_ENVIRONMENT + "_mpd"}>MPC
                    status</Dropdown.Item>
                <Dropdown.Item
                    href={"taleaSSH://logs_" + props.playerId + "@" + TALEA_ENVIRONMENT + "_talea"}>MPC
                    playlist</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </>;

}
