import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { playlistActions } from '_actions';
import { CreationForms, DefaultBlackFormControl, DefaultColumnContainer, DefaultLabel, Helper, theme } from "_styles";
import Select, { Creatable } from 'react-select';
import CreatableEditableSelect from "./Standard/CreatableEditableSelect";
import { playlistService } from "../_services";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const FormLabel = styled(DefaultLabel)`
    color: ${props => props.theme.formlabel};
    font-size: 14px;
`;

const Form = styled(DefaultBlackFormControl)`
    margin-top: 5px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: black;
    }
`;

const RSelect = styled(Select)`
    width: 100%;
    margin-top: 10px;
`;

const DetailsContainer = styled(DefaultColumnContainer)`
    width: 100%;
    align-items: flex-start;

    & + & {
        margin-top: 20px;
    }
`;

const Label = styled(DefaultLabel)`
    color: white;
    font-size: 24px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
`;

class EditPlaylistDetails extends React.Component {
  state = {
    name: (this.props.duplicate ? this.props.name + " copy" : this.props.name),
    description: this.props.description,
    internalDescription: this.props.internalDescription,
    submitting: false,
    group: this.props.group,
    selectedExplicitType: this.props.allowedExplicitType,
    error: '',
    tags: this.props.tags ? this.props.tags : [],
    duplicate: this.props.duplicate,
    client: null,
    clients: this.props.clients,
    playlistTags: [],
    availableGroups: []
  };

  changeExplicitType = (explicitType) => {
    this.setState({selectedExplicitType: explicitType})
  };

  componentDidMount() {
    this.setState({availableGroups: this.props.availableGroups});
    playlistService.getPlaylistTags(this.props.dispatch).then(playlistTags => {
      this.setState({playlistTags});
    });
  }

  handleName = e => {
    this.setState({name: e.target.value});
  };


  handleClient = client => {
    const playlistsGroups = client.value ? [...new Set(this.props.playlists.filter(p => p.client && p.client.id === client.value && p.playlistGroup).map(p => p.playlistGroup))] :
        [...new Set(this.props.playlists.filter(p => !p.client && p.playlistGroup).map(p => p.playlistGroup))];
    this.setState({client: client, availableGroups: playlistsGroups, group: null});
  };

  handleDescription = e => {
    this.setState({description: e.target.value});
  };

  handleInternalDescription = e => {
    this.setState({internalDescription: e.target.value});
  };

  handleTags = tags => {
    this.setState({tags});
  };

  handleGroup = e => {
    let selectedGroup = e.value;
    this.setState({group: selectedGroup ? selectedGroup.trim() : undefined});
  };
  handleTagSelection = e => {
    this.setState({tags: e.map(tag => tag.value)});
  };

  handleSubmit = async (e) => {
    if (this.state.name.trim().length === 0) {
      this.setState({submitting: false, error: 'Please enter a name'});
      return;
    }
    if (this.state.tags.length === 0) {
      this.setState({submitting: false, error: 'Please at least one tag.'});
      return;
    }
    if (!this.state.selectedExplicitType) {
      this.setState({submitting: false, error: 'Please select an explicit type.'});
      return;
    }

    this.setState({submitting: true, error: ''});
    if (!this.state.duplicate) {
      try {
        const group = this.state.group;
        playlistActions.editPlaylistDetails(this.props.dispatch, {
          id: this.props.id,
          name: this.state.name,
          playlistGroup: group && group.trim().length > 0 ? group : null,
          description: this.state.description,
          tags: this.state.tags,
          internalDescription: this.state.internalDescription,
          allowedExplicitType: this.state.selectedExplicitType
        });
        this.setState({submitting: false});
        this.props.onEdit();
      } catch (e) {
        this.setState({submitting: false, error: e.toString()});
      }
    } else {
      try {
        let client = this.state.client;
        const group = this.state.group;
        playlistActions.duplicate(this.props.dispatch, {
          id: this.props.id,
          name: this.state.name,
          description: this.state.description,
          internalDescription: this.state.internalDescription,
          tags: this.state.tags,
          playlistGroup: group && group.trim().length > 0 ? group : null,
          allowedExplicitType: this.state.selectedExplicitType,
          client: client ? {id: client.value} : null,
        });
        this.setState({submitting: false});
        this.props.onEdit(this.state.name);
      } catch (e) {
        this.setState({submitting: false, error: e.toString()});
      }
    }
  };

  render() {
    let message = this.state.duplicate ? "This will duplicate the playlist, please check the information entered above is correct.\nPemding playlists additions/removals won't be duplicated." : "This will edit the playlist name/group, please check the information entered above is correct.";

    function titleCase(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      return splitStr.join(' ');
    }

    const playlistTagsWithoutDuplicatesCaseInsensitive = [...new Set(this.state.playlistTags.map(tag => titleCase(tag.trim())))];
    return (
        <ThemeProvider theme={theme}>
          <CreationForms
              message={message}
              handleBackButton={this.props.onBack}
              handleSubmit={this.handleSubmit}
              submitting={this.state.submitting}
              alert={this.state.error}
              title={this.state.duplicate ? "Duplicate Playlist" : "Edit Playlist Details"}
          >
            {this.state.duplicate &&
                <DetailsContainer>
                  <Label>Choose Client</Label>
                  <RSelect
                      options={this.state.clients && this.state.clients.sort((a, b) => a.name.localeCompare(b.name)).map(client => ({
                        value: client.id,
                        label: client.name
                      }))}
                      name="client-list"
                      value={this.state.client}
                      onChange={this.handleClient}
                  />
                  <Helper message="Choose one client" />
                </DetailsContainer>
            }
            <FormLabel>Name</FormLabel>
            <Form
                type="text"
                name="name"
                placeholder="Name"
                value={this.state.name}
                onChange={this.handleName}
            />
            <FormLabel style={{marginTop: 10}}>Description</FormLabel>
            <Form
                type="text"
                name="description"
                placeholder="Description"
                value={this.state.description}
                onChange={this.handleDescription}
            />
            <FormLabel style={{marginTop: 10}}>Notes</FormLabel>
            <Form
                type="text"
                name="internalDescription"
                placeholder="Notes"
                value={this.state.internalDescription}
                onChange={this.handleInternalDescription}
            />
            <FormLabel style={{marginTop: 10}}>Tags</FormLabel>
            <Creatable
                styles={{
                  control: styles => ({...styles, height: 50, marginTop: 10})
                }}
                options={playlistTagsWithoutDuplicatesCaseInsensitive.sort((p1, p2) => {
                  return p1.toLowerCase().localeCompare(p2.toLowerCase());
                }).map(e => {
                  return {value: e, label: e}
                })}
                isMulti={true}
                isClearable={true}
                name="tags"
                onChange={this.handleTagSelection}
                value={this.state.tags.map(tag => {
                  return {value: tag, label: tag}
                })}
            />
            <FormLabel style={{marginTop: 10, marginBottom: 10}}>Explicit Allowed</FormLabel>
            <RadioGroup row>
              <FormControlLabel style={{marginBottom: 0, color: "white"}} value="no"
                  control={<Radio
                      checked={this.state.selectedExplicitType === "CLEAN"}
                      onChange={() => this.changeExplicitType("CLEAN")}
                      style={{color: "#EBAD18"}} />} label="Clean" />
              <FormControlLabel style={{marginBottom: 0, color: "white"}} value="adult"
                  control={<Radio
                      checked={this.state.selectedExplicitType === "ADULT"}
                      onChange={() => this.changeExplicitType("ADULT")}
                      style={{color: "#EBAD18"}} />}
                  label="Adult" />
              <FormControlLabel style={{marginBottom: 0, color: "white"}} value="explicit"
                  control={<Radio
                      checked={this.state.selectedExplicitType === "EXPLICIT"}
                      onChange={() => this.changeExplicitType("EXPLICIT")}
                      style={{color: "#EBAD18"}} />}
                  label="Explicit" />
            </RadioGroup>
              <FormLabel style={{marginTop: 10, marginBottom: 10}}>Group</FormLabel>
              <CreatableEditableSelect
                  styles={{
                    control: styles => ({...styles, height: 50, marginTop: 10})
                  }}
                  options={this.state.availableGroups.sort().map(e => {
                    return {value: e, label: e}
                  })}
                  isClearable={true}
                  name="group"
                  onChange={this.handleGroup}
                  value={{value: this.state.group, label: this.state.group}}
              />
          </CreationForms>
        </ThemeProvider>
    );
  }
}

const _editPlaylistDetails = withRouter(connect()(EditPlaylistDetails));
export { _editPlaylistDetails as EditPlaylistDetails };
