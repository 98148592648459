import React, { useState } from "react";
import styled from "styled-components";
import { SearchFilter } from './SearchFilter';
import { IconCheck, IconPlay, IconPlus, SortTable } from "./BlitzTable";
import { Col, Container, Row } from "react-bootstrap";
import { playerActions, playlistActions } from "../../_actions";
import { getActivePlaylistId, getPlaylistLookupView, } from "../../_reducers/playlist.reducer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const ContainerStyled = styled(Container)`
    padding: 10px;
    color: #BBB;
    user-select: none;
    min-width: 500px;
    background-color: #222128;
    border-radius: 5px;
    border: 1px solid #525252;
    margin-top: 10px;
`;

const SelectionContextMenuSongs = ({pinnedPlaylistId, pinnedPlaylistSongs, songsData, dispatch, songStatusChanged}) => {
  const [filter, setFilter] = useState('');

  const handleFilter = text => {
    setFilter(text);
  }

  const handleAddSong = song => {
    playlistActions.addSongs(dispatch, pinnedPlaylistId, [song.id])
    if (songStatusChanged) {
      songStatusChanged(song);
    }

  };

  const handleDeleteSong = song => {
    playlistActions.deleteSongs(dispatch, pinnedPlaylistId, [song.id])
    if (songStatusChanged) {
      songStatusChanged(song);
    }
  };

  const playSong = (song) => {
    playerActions.play(dispatch, song.id, song.title, song.artist.name, song.artworkUrl);
  }

  const filteredSongs = songsData.filter(s => s.title.toLowerCase().includes(filter.toLowerCase()) || s.artist.name.toLowerCase().includes(filter.toLowerCase()));

  let columns = () => [
    {
      fixed: 30, rowRenderer: row => {
        return pinnedPlaylistSongs[row.id] ?
            <IconCheck color='orange' onClick={e => {
              e.stopPropagation();
              handleDeleteSong(row)
            }} />
            :
            <IconPlus onClick={e => {
              e.stopPropagation();
              handleAddSong(row)
            }} />

      }
    },
    {
      fixed: 30, rowRenderer: row => (
          <div className="onRowHover">
                    <IconPlay onClick={e => {
                      e.stopPropagation();
                      playSong(row);
                    }} />
                </div>
      )
    },
    {percent: 0.4, field: 'title', title: 'Title', isNumber: true},
    {percent: 0.4, field: 'artist.name', title: 'Artist', isNumber: true}
  ];

  return (
      <ContainerStyled>
            <Row>
                <Col style={{maxHeight: 600, overflowY: "scroll"}}>
                    <Row>
                        <Col xs={12}>
                            <SearchFilter onChange={handleFilter} value={filter} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SortTable
                                tableName={'songs_context'}
                                defaultSortField='title'
                                defaultSortOrder='desc'
                                loading={false}
                                rows={filteredSongs}
                                columns={columns()}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ContainerStyled>
  )
}

const mapStateToProps = (state)  => {
  const pinnedPlaylistId = getActivePlaylistId(state);
  const pinnedPlaylistSongs = getPlaylistLookupView(state, pinnedPlaylistId);
  return {
    pinnedPlaylistId,
    pinnedPlaylistSongs
  };
};

const connectedSelectionContextMenuSongs = withRouter(connect(mapStateToProps)(SelectionContextMenuSongs));
export { connectedSelectionContextMenuSongs as SelectionContextMenuSongs };